// {
//     id: "attendance",
//     type: "normal-select",
//     label: testType === "Scheduled Tests" ? "Attendance *" : "Attendance",
//     list: "attendance_status",
//     className: "select-styles",
//     required: isRequiered,
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     options: [],
//     onlyApiCall: true,
//       requestList: async (payload) => {
//         return {};
//       },
//     endpoint:endpointsMd.GET_ATTENDANCE_STATUS_LIST,
//     keyValue: { label: "attendance_status_name", value: "attendance_status_id" },
//     //keyValue: { label: "name", value: "object_id" },
//     disabled: isDisabled,
//   },

import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export type districtFilter = {
    district_list: string;
    start_date: string;
    end_date: string;
    isArchived:string;
    isDummy:string;
};

export const districtFilterInitialValues: districtFilter = {
    district_list: "",
    start_date: "",
    end_date: "",
    isArchived:"false",
    isDummy:"false"
};

export const districtFilterMandatoryFields = [
  { id: "district_list" },
  { id: "start_date" },
  { id: "end_date" },
];

export const districtFilterValidationSchema = Yup.object().shape({

});

export const districtFilterProperties = [
  {
    id: "district_list",
    type: "normal-select",
    label: "District",
    list: "district_status",
    className: "select-styles",
    required: false,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    onlyApiCall: true,
      requestList: async (payload) => {
        return {};
      },
    endpoint:endpointsMd.GET_DISTRICT_LIST,
    keyValue: { label: "district_name", value: "district_name" },
    //keyValue: { label: "name", value: "object_id" },
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isDummy",
    type: "normal-select",
    label: "isDummy",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  
];

export function formatDate(isoString) {
    if(isoString==""){
        return "";
    }
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }