import * as Yup from "yup";
export const table = {
    primaryData: [
      {
        title: "State",
        value: "name",
        type: "configue",
      },
      {
        title: "SbdStateId",
        value: "sbdStateId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [
    ],
  };
export const tableRead = {
    primaryData: [
      {
        title: "State",
        value: "name",
        type: "configue",
      },
      {
        title: "SbdStateId",
        value: "sbdStateId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      }
    ],
    secondaryData: [
    ],
  };

  export type stateCreate = {
    name: string;
    sbdStateId: string;
    isDummy:Boolean|string;
};

export const stateCreateInitialValues: stateCreate = {
    name: "",
    sbdStateId: "",
    isDummy:"false"
};

export const stateCreateMandatoryFields = [
  { id: "name" },
  { id: "sbdStateId" }
];

export const stateCreateValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sbdStateId: Yup.number()
  .typeError("SBD State ID must be a number")
  .integer("SBD State ID must be an integer")
  .required("SBD State ID is required"),
});

export const stateCreateProperties = [
    {
        id: "name",
        type: "text",
        label: `Name`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "sbdStateId",
        type: "text",
        label: `sbdStateId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
];
export const stateUpdateProperties = [
    {
        id: "name",
        type: "text",
        label: `Name`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "sbdStateId",
        type: "text",
        label: `sbdStateId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "isDummy",
        type: "normal-select",
        label: "isDummy",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
      {
        id: "isArchived",
        type: "normal-select",
        label: "IsArchived",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
];
