import * as Yup from "yup";
export type yearFilter = {
    academic_year_id: string;
    start_date: string;
    end_date: string;
    isArchived:string;
};

export const yearFilterInitialValues: yearFilter = {
    academic_year_id: "",
    start_date: "",
    end_date: "",
    isArchived:"false"
};

export const yearFilterMandatoryFields = [
  { id: "academic_year_id" },
  { id: "start_date" },
  { id: "end_date" },
];

export const yearFilterValidationSchema = Yup.object().shape({

});

export const yearFilterProperties = [
  {
    id: "academic_year_id",
    type: "normal-select",
    label: "Academics",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_name" },
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  
];
