import * as Yup from "yup";
export type sbdProgramFilter = {
    sbdProgramName: string;
    start_date: string;
    end_date: string;
    isArchived:string;
};

export const sbdProgramFilterInitialValues: sbdProgramFilter = {
    sbdProgramName: "",
    start_date: "",
    end_date: "",
    isArchived:"false"
};

export const sbdProgramFilterMandatoryFields = [
  { id: "sbdProgramName" },
  { id: "start_date" },
  { id: "end_date" },
];

export const sbdProgramFilterValidationSchema = Yup.object().shape({

});

export const sbdProgramFilterProperties = [
  {
    id: "sbdProgramName",
    type: "text",
    label: `sbd Program`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: false,
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  
];

export function formatDate(isoString) {
    if(isoString==""){
        return "";
    }
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }