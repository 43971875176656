import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_BATCH_LIST } from "utils/graphql/services/entities/queries";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import { table, tableRead } from "./helper";
import NoDataFound from "shared/molecules/no-data";
import { BatchFilter } from "./filter";
import { DELETE_BATCH } from "utils/graphql/services/entities/mutations";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { isEmpty } from "utils/helpers";
import { getValuesByIds } from "pages/entities/helper";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getBatchListData } from "utils/metadata-split/metaDataApiFetch";
import { academicTagsUser, checkFeatureAccess } from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function BatchEntitie() {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [clearValuesTrigger, setClearValuesTrigger] = useState(false);

  const [getBatches, { loading, error, data }] = useLazyQuery(GET_BATCH_LIST, {
    client: entitesClient,
  });

  const [deleteBatch, { loading: loadingD, error: errorD, data: dataD }] =
    useMutation(DELETE_BATCH, {
      client: entitesClient, // Ensure you're using the correct GraphQL client instance
    });

    const { isLoading, data: metaData }: any = useAppSelector(
      (state) => state.metadataReducer
    );

    const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
    const { role: userRoleStr } = profileData;
    const userRole = userRoleStr?.split(",");

    const updateMetaData = (listData, metaKey) => {
      let metadataList = { ...metaData };
      metadataList[metaKey] = listData;
      dispatch(metadataSuccess(metadataList));
    };
  
    const handleUpdateBatchListDate = async () => {
      try {
       const call1:any= await getBatchListData(updateMetaData);
        updateMetaData(call1[0], call1[1]);
      } catch (error) {
        console.log(error);
      }
     };

  const handleEdit = (objectId,isArchieve,isDummy) => {
    // const { objectId } = obj;
    history.push(`/entities/batch/edit?objectId=${objectId}&isArchived=${isArchieve}&isDummy=${isDummy}`);
  };

  const handleDeleteBatch = async (objectId) => {
    try {
      const response: any = await deleteBatch({
        variables: { objectId },
      });

      if (response?.data?.deleteBatch?.success) {
        console.log(
          "Batch deleted successfully:",
          response?.data?.deleteBatch?.message
        );
        dispatch(snackbarRequest({ message: "Batch deleted successfully" }));
        await handleUpdateBatchListDate();
        setClearValuesTrigger(!clearValuesTrigger);
        // getBatches({ variables: { filter: { limit: 10, offset: 0 } } });
        // setLimit(10);
        // setOffset(0);
      } else {
        console.log(
          "Batch deletion failed:",
          response?.data?.deleteBatch?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error deleting batch:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };
  useEffect(() => {
    if (data?.getBatch) {
      const { getBatch } = data;
      const { exam_model_list } = metaData;
      if (!isEmpty(getBatch)) {
        // convertToTableData();//exam_model_list
        const tabledata = getBatch.map((obj) => {
          console.log("obj", obj);
          const tableRow = { ...obj };
          tableRow["examModelList"] = getValuesByIds(obj?.examModelList,
            "exam_model_id","exam_model_name","exam_model_list",metaData
          );
          return tableRow;
        });
        setTableData(tabledata);
      } else {
        setTableData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    getBatches({ variables: { filter: {...searchPayload,limit,offset} } });
   // getBatches({ variables: { filter: { ...searchPayload } } });
  }, [searchPayload, offset]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  return (
    <div>
      {hasModificationAccess && (
        <ContentRight>
          <Link to="batch/create" className={classes.label}>
            <Button variant="contained">Create Batch </Button>
          </Link>
        </ContentRight>
      )}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        Batch
      </TypographyHeading>
      <BatchFilter
        setSearchPayload={setSearchPayload}
        setOffset={setOffset}
        clearValuesTrigger={clearValuesTrigger}
      />
      <Paper elevation={2} sx={{ px: 2, pb: 4, paddingTop: 2, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && tableData.length > 0 ? (
            <SectionTable
              data={tableData}
              table={hasModificationAccess===true?table:tableRead}
              id="objectId"
              name="objectId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteBatch}
              rowsPerPageCount={10}
             // rowsPerPageCount={0}
              style="false"
              totlaRecords={tableData?.length}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            // <CollapsibleTable
            //   data={tableData}
            //   table={table}
            //   id="objectId"
            //   name="objectId"
            //   handleEdit={handleEdit}
            //   handleDelete={handleDeleteBatch}
            //   rowsPerPageCount={
            //     tableData?.length > 0 ? tableData?.length : 10
            //   }
            //   style="false"
            //   totlaRecords={0}
            //   paginationSegmentApi="false"
            //   hasPageApiCall={false}
            //   showPaginationFooter={false}
            // />
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={tableData?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
