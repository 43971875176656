import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  batchInitialValues,
  batchMandatoryFields,
  batchProperties,
  batchPropertiesUpdate,
  batchValidationSchema,
  setCreateBatchMetaData,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import {
  CREATE_BATCH,
  UPDATE_BATCH,
} from "utils/graphql/services/entities/mutations";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { GET_BATCH_LIST } from "utils/graphql/services/entities/queries";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";

export const BatchUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    batchInitialValues,
    batchValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);
  const isDummy = getQueryParam("isDummy", location);

  //   const [createBatch, { loading, error, data }] = useMutation(CREATE_BATCH, {
  //     client: entitesClient,
  //   });
  const [getBatches, { loading, error, data }] = useLazyQuery(GET_BATCH_LIST, {
    client: entitesClient,
  });

  const [updateBatch, { loading: loadingUp, error: errorUp, data: dataUp }] =
    useMutation(UPDATE_BATCH, {
      client: entitesClient,
    });

  const handleUpdateBatch = async () => {
    try {
      const response: any = await updateBatch({
        variables: {
          input: {
            objectId: objectId,
            name: watch()?.name,
            platform: watch()?.platform,
            sbdBatchId: watch()?.sbdBatchId,
            examModelList: watch()?.examModelList,
            metadata: setCreateBatchMetaData(watch()),
            isDummy:watch()?.isDummy=="true"?true:false,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateBatch?.success) {
        console.log(
          "Batch updated successfully:",
          response?.data?.updateBatch?.message
        );
        dispatch(snackbarRequest({ message: "Updated Batch successfully" }));
        history.replace(`/entities/batch`);
      } else {
        console.log(
          "Batch update failed:",
          response?.data?.updateBatch?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating batch:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };
  //   const handleCreateBatch = async () => {

  //     try {
  //       const response:any = await createBatch({
  //         variables: {
  //           input: {
  //             name: watch()?.name,
  //             platform: watch()?.platform,
  //             sbdBatchId: watch()?.sbdBatchId,
  //             examModelList: watch()?.examModelList,
  //             metadata: setCreateBatchMetaData(watch())
  //           },
  //         },
  //       });

  //       if(!isEmpty(response?.data?.createBatch)){
  //         dispatch(snackbarRequest({ message: "Created Batch successfully" }));
  //         history.replace(`/entities/batch`);
  //       }else{
  //         dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));

  //       }
  //     } catch (err) {
  //       dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));

  //     }
  //   };
  useEffect(() => {
    if(data?.getBatch){
        const{getBatch}=data
        if(!isEmpty(getBatch[0])){
            setValue("name",getBatch[0]?.name);
            setValue("platform",getBatch[0]?.platform);
            setValue("sbdBatchId",getBatch[0]?.sbdBatchId);
            setValue("examModelList",getBatch[0]?.examModelList);
            setValue("isRevisenowNotificationDisabled",getBatch[0]?.metadata?.isRevisenowNotificationDisabled==true?"true":"false");
            setValue("isDigital",getBatch[0]?.metadata?.isDigital==true?"true":"false");
            setValue("isPracticeDisabled",getBatch[0]?.metadata?.isPracticeDisabled==true?"true":"false");
            setValue("isDoubtSolvingDisabled",getBatch[0]?.metadata?.isDoubtSolvingDisabled==true?"true":"false");
            setValue("isDigitalNarayanaEnabled",getBatch[0]?.metadata?.isDigitalNarayanaEnabled==true?"true":"false");
            setValue("isDummy",getBatch[0]?.isDummy==true?"true":"false");
            setValue("isArchived",getBatch[0]?.isArchived==true?"true":"false");

        }
    }
  }, [data]);

  useEffect(() => {
    getBatches({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
          isDummy: isDummy === "true" ? true : false,
        },
      },
    });
  }, []);
  
  return (
    <div>
      <TypographyHeading>Update Batch</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={batchPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateBatch}
              disabled={!isFieldsValidate(errors, batchMandatoryFields, getValues)}
              >
              Update Batch
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
