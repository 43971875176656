import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export const table = {
    primaryData: [
      {
        title: "Class",
        value: "Class",
        type: "configue",
      },
      {
        title: "Batch",
        value: "Batch",
        type: "configue",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [],
  };
export const tableRead = {
    primaryData: [
      {
        title: "Class",
        value: "Class",
        type: "configue",
      },
      {
        title: "Batch",
        value: "Batch",
        type: "configue",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      }
    ],
    secondaryData: [],
  };

  export type classBatchMap = {
    Class: string;
    Batch: string;
};

export const classBatchMapInitialValues: classBatchMap = {
    Class: "",
    Batch: "",
};

export const classBatchMapMandatoryFields = [
  { id: "Class" },
  { id: "Batch" }
];

export const classBatchMapValidationSchema = Yup.object().shape({
  Class: Yup.string().required("Class is required"),
  Batch: Yup.string().required("Batch is required"),
});

export const classBatchMapProperties = [
    {
      id: "Class",
      type: "single-select-dependency",
      label: "Class *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.5 },
      options: [],
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: [""],
      dependentFlds: {
      },
    },
      {
        id: "Batch",
        type: "normal-select",
        label: "Batch *",
        list: "batch_list",
        className: "select-styles",
        required: false,
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: [],
        onlyApiCall: true,
          requestList: async (payload) => {
            return {};
          },
        endpoint:endpointsMd.GET_BATCH_LIST,
        keyValue: { label: "batch_name", value: "batch_id" },
      },
];
export const classBatchMapPropertiesUpdate = [
    {
      id: "Class",
      type: "single-select-dependency",
      label: "Class *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.5 },
      options: [],
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: [""],
      dependentFlds: {
      },
    },
      {
        id: "Batch",
        type: "normal-select",
        label: "Batch *",
        list: "batch_list",
        className: "select-styles",
        required: false,
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: [],
        onlyApiCall: true,
          requestList: async (payload) => {
            return {};
          },
        endpoint:endpointsMd.GET_BATCH_LIST,
        keyValue: { label: "batch_name", value: "batch_id" },
      },
      {
        id: "isArchived",
        type: "normal-select",
        label: "IsArchived",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
];
