import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  classCreateInitialValues,
  classCreateMandatoryFields,
  classCreateProperties,
  classCreateValidationSchema,
  classUpdateProperties,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CLASS,
  UPDATE_CLASS,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import { GET_CLASS_LIST } from "utils/graphql/services/entities/queries";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getClassListData } from "utils/metadata-split/metaDataApiFetch";

export const ClassUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    classCreateInitialValues,
    classCreateValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);
  const isDummy = getQueryParam("isDummy", location);

  const [getClass, { loading, error, data }] = useLazyQuery(GET_CLASS_LIST, {
    client: entitesClient,
  });

  const [updateClass, { loading: loadingUp, error: errorUp, data: dataUp }] =
    useMutation(UPDATE_CLASS, {
      client: entitesClient, // Ensure you're using the correct GraphQL client
    });

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateClassCourseListDate = async () => {
    try {
      const call1: any = await getClassListData(updateMetaData);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateClass = async () => {
    try {
      const response: any = await updateClass({
        variables: {
          input: {
            objectId:objectId,
            name: watch()?.name,
            synonyms:watch()?.synonyms.length>0?watch()?.synonyms.split(","):watch()?.name,
              // watch()?.synonyms?.length === 1
              //   ? watch()?.synonyms[0]
              //   : watch()?.synonyms?.length > 1
              //   ? watch()?.synonyms?.join(",")
              //   : watch()?.name,
            sbdClassId: watch()?.sbdClassId,
            isDummy: watch()?.isDummy == "true" ? true : false,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateClass?.success) {
        console.log(
          "Class updated successfully:",
          response?.data?.updateClass?.message
        );
        dispatch(snackbarRequest({ message: "Updated Class successfully" }));
        await handleUpdateClassCourseListDate();
        history.replace(`/entities/class`);
      } else {
        console.log(
          "Class update failed:",
          response?.data?.updateClass?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating class:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    if (data?.getClass) {
      const { getClass } = data;
      if (!isEmpty(getClass[0])) {
        setValue("name", getClass[0]?.name);
        setValue("synonyms", getClass[0]?.synonyms?.join(","));
        setValue("sbdClassId", getClass[0]?.sbdClassId);
        setValue("isDummy", getClass[0]?.isDummy == true ? "true" : "false");
        setValue(
          "isArchived",
          getClass[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);

  useEffect(() => {
    getClass({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
          isDummy: isDummy === "true" ? true : false,
        },
      },
    });
  }, []);
 
  return (
    <div style={{ height: "80px" }}>
      <TypographyHeading>Update class</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={classUpdateProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateClass}
              disabled={
                !isFieldsValidate(errors, classCreateMandatoryFields, getValues)
              }
            >
              Update class
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
