import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_STATE_LIST } from "utils/graphql/services/entities/queries";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import NoDataFound from "shared/molecules/no-data";
import { table, tableRead } from "./helper";
import { StateFilter } from "./filter";
import { DELETE_STATE } from "utils/graphql/services/entities/mutations";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getStateListData } from "utils/metadata-split/metaDataApiFetch";
import {
  academicTagsUser,
  checkFeatureAccess,
} from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function StateEntitie() {
  const classes = useStyles();
  //   const { data, loading, error } = useQuery<any>(GET_STATE_LIST, {
  //     variables: {    filter: {}},
  //       client:entitesClient
  //   });
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [getStates, { loading, error, data }] = useLazyQuery(GET_STATE_LIST, {
    client: entitesClient,
  });

  const [deleteState, { loading: loadingD, error: errorD, data: dataD }] =
    useMutation(DELETE_STATE, {
      client: entitesClient,
    });

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateStateListDate = async () => {
    try {
      const call1: any = await getStateListData(updateMetaData);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (objectId,isArchieve,isDummy) => {
    history.push(`/entities/state/edit?objectId=${objectId}&isArchived=${isArchieve}&isDummy=${isDummy}`);
  };

  const handleDeleteState = async (objectId) => {
    try {
      const response: any = await deleteState({
        variables: { objectId },
      });

      if (response?.data?.deleteState?.success) {
        console.log(
          "State deleted successfully:",
          response?.data?.deleteState?.message
        );
        dispatch(snackbarRequest({ message: "State deleted successfully" }));
        getStates({ variables: { filter: { limit: 10, offset: 0 } } });
        await handleUpdateStateListDate();
        setLimit(10);
        setOffset(0);
      } else {
        console.log(
          "State deletion failed:",
          response?.data?.deleteState?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error deleting state:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    getStates({ variables: { filter: { ...searchPayload, limit, offset } } });
    // getStates({ variables: { filter: {...searchPayload} } });
  }, [searchPayload, offset]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  return (
    <div>
      {hasModificationAccess && (
        <ContentRight>
          <Link to="state/create" className={classes.label}>
            <Button variant="contained">Create State</Button>
          </Link>
        </ContentRight>
      )}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        State
      </TypographyHeading>
      <StateFilter setSearchPayload={setSearchPayload} setOffset={setOffset} />
      <Paper elevation={2} sx={{ px: 2, pb: 4, paddingTop: 2, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && data?.getState.length > 0 ? (
            <SectionTable
              data={data?.getState}
              table={hasModificationAccess===true?table:tableRead}
              id="objectId"
              name="objectId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteState}
              rowsPerPageCount={10}
              style="false"
              totlaRecords={0}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            // <CollapsibleTable
            //   data={data?.getState}
            //   table={table}
            //   id="objectId"
            //   name="objectId"
            //   handleEdit={handleEdit}
            //   handleDelete={handleDeleteState}
            //   rowsPerPageCount={data?.getState?.length > 0 ? data?.getState?.length : 10}
            //   style="false"
            //   totlaRecords={0}
            //   paginationSegmentApi="false"
            //   hasPageApiCall={false}
            //   showPaginationFooter={false}
            // />
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data?.getState?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
