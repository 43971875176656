export function generateFibArray(xml: string, str: string) {
    // Parse the XML string
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/html'); // Use 'text/html' for compatibility with contenteditable
    
    // Log if parsing failed (DOMParser may fail silently)
    if (!xmlDoc) {
        console.error("XML Parsing failed.");
        return [];
    }

    // Parse the string input to create a map of fib identifiers to values
    const fibValues = str ? str.split(';').reduce((acc, pair) => {
        const [key, value] = pair.split(':');
        if (key && value) {
            acc[key.trim()] = `<p>${value.trim()}</p>`;
        }
        return acc;
    }, {} as Record<string, string>) : {};

    console.log("Parsed fibValues:", fibValues);

    // Select all <fib> elements
    const fibNodes = xmlDoc.querySelectorAll('fib');
    if (fibNodes.length === 0) {
        console.warn("No <fib> tags found in the parsed XML.");
    }

    // Create the result array by mapping <fib> elements to desired objects
    const resultArray = Array.from(fibNodes).map(fib => {
        const fibIdentifier = fib.getAttribute('fib_identifier') || "";
        return {
            id: fib.getAttribute('id') || "",
            charlength: fib.getAttribute('charlength') || "",
            fib_identifier: fibIdentifier,
            answer_pattern_identifier: fib.getAttribute('answer_pattern_identifier') || "",
            case_sensitive: fib.getAttribute('case_sensitive') || "",
            marking_schema_identifier: fib.getAttribute('marking_schema_identifier') || "",
            ans_val: fibValues[fibIdentifier] || "" // Retrieve ans_val from fibValues based on fib_identifier
        };
    });

    console.log("Generated resultArray:", resultArray); // Debug output for final array
    return resultArray;
}


export function updateFibTagsWithPlaceholder(xml: string): string {
    // Parse the XML string
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/html'); // Using 'text/html' for compatibility
    
    // Select all <fib> elements
    const fibNodes = xmlDoc.querySelectorAll('fib');

    // Update each <fib> element's text content to "____"
    fibNodes.forEach(fib => {
        fib.textContent = '____';
    });

    // Serialize the updated DOM back to a string
    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc.body);
}
