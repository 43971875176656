export const createQuestionSelector = (state) => state.createQuestionSlice;
export const questionEditorsSlice = (state) => state.questionEditorsSlice;
export const router = (state) => state.router;
export const questionGroupDetailsSelector = (state) =>
  state.getQuestionGroupListDetailsByIDSlice;
export const subQuestionGroupItemListSelector = (state) =>
  state.subQuestionGroupItemListSlice;
export const paragraphQuestionsSliceSelector = (state) =>
  state.paragraphQuestionsSlice;
export const fibQuestionsSliceSelector = (state) =>
  state.fibQuestionsSlice;

