import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CLASS_LIST,
  GET_CLASSBATCHMAPPING_LIST,
} from "utils/graphql/services/entities/queries";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import { table, tableRead } from "./helper";
import NoDataFound from "shared/molecules/no-data";
import { ClassBatchFilter } from "./filter";
import { DELETE_CLASS_BATCH_MAPPING } from "utils/graphql/services/entities/mutations";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { isEmpty } from "utils/helpers";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  getBatchListData,
  getClassListData,
} from "utils/metadata-split/metaDataApiFetch";
import LoaderModal from "shared/molecules/loader-modal";
import { academicTagsUser, checkFeatureAccess } from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function ClassBatchMapEntitie() {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showLoading, setShowIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const [getClassBatchMapping, { loading, error, data }] = useLazyQuery(
    GET_CLASSBATCHMAPPING_LIST,
    {
      client: entitesClient,
    }
  );

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const [
    deleteClassBatchMapping,
    { loading: loadingD, error: errorD, data: dataD },
  ] = useMutation(DELETE_CLASS_BATCH_MAPPING, {
    client: entitesClient, // Ensure you're using the correct GraphQL client
  });

  const updateMetaData = (listData, metaKey, listData2, metaKey2) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    dispatch(metadataSuccess(metadataList));
    setShowIsLoading(false);
  };

  const handleDeleteClassBatchMapping = async (id) => {
    try {
      const response: any = await deleteClassBatchMapping({
        variables: {
          objectId: id,
        },
      });

      if (response?.data?.deleteClassBatchMapping?.success) {
        console.log(
          "Class-Batch Mapping deleted:",
          response?.data?.deleteClassBatchMapping?.message
        );
        dispatch(
          snackbarRequest({ message: "Class Batch Map deleted successfully" })
        );
        getClassBatchMapping({
          variables: { filter: { limit: 10, offset: 0 } },
        });
        setLimit(10);
        setOffset(0);
      } else {
        console.log(
          "Class-Batch Mapping deletion failed:",
          response?.data?.deleteClassBatchMapping?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error deleting Class-Batch Mapping:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  const handleEdit = (objectId,isArchieve) => {
    history.push(`/entities/class_batch_map/edit?objectId=${objectId}&isArchived=${isArchieve}`);
  };

  const convertToTableData = (
    metaArray,
    object_id,
    identifier,
    return_Value
  ) => {
    const filteredObj = metaArray.filter((obj) => {
      if (obj[`${identifier}`] === object_id) {
        return obj;
      }
    });
    return filteredObj?.length > 0 ? filteredObj[0][`${return_Value}`] : "-";
  };

  useEffect(() => {
    if (data?.getClassBatchMapping) {
      const { getClassBatchMapping } = data;
      const { class_course_batch_data, batch_list } = metaData;
      if (!isEmpty(getClassBatchMapping)) {
        // convertToTableData();
        const tabledata = getClassBatchMapping.map((obj) => {
          console.log("obj", obj);
          const tableRow = { ...obj };
          tableRow["Class"] = convertToTableData(
            class_course_batch_data,
            obj[`Class`],
            "class_id",
            "class_name"
          );
          tableRow["Batch"] = convertToTableData(
            batch_list,
            obj[`Batch`],
            "batch_id",
            "batch_name"
          );
          return tableRow;
        });
        setTableData(tabledata);
      } else {
        setTableData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (showLoading === false) {
      getClassBatchMapping({
        variables: { filter: { ...searchPayload, limit, offset } },
      });
    }
  }, [searchPayload, limit, offset, showLoading]);

  useEffect(() => {
    (async () => {
      try {
        const call1: any = await getClassListData(updateMetaData);
        const call2: any = await getBatchListData(updateMetaData);
        updateMetaData(call1[0], call1[1], call2[0], call2[1]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const hasModificationAccess = checkFeatureAccess(
    userRole,academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  if (showLoading) {
    return <p>fetching class Batch data Please wait....</p>;
    // return <LoaderModal message="fetching class Batch data Please wait...."  />;
  }

  return (
    <div>
      {hasModificationAccess && (
        <ContentRight>
          <Link to="class_batch_map/create" className={classes.label}>
            <Button variant="contained"> Class Batch Map</Button>
          </Link>
        </ContentRight>
      )}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        Class Batch Mapping
      </TypographyHeading>
      <ClassBatchFilter
        setSearchPayload={setSearchPayload}
        setOffset={setOffset}
      />
      <Paper elevation={2} sx={{ px: 2, pb: 4, pt: 2, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {/* {!loading && data?.getClassBatchMapping.length > 0 ? ( */}
          {!loading && tableData?.length > 0 ? (
            <SectionTable
              // data={data?.getClassBatchMapping}
              data={tableData}
              table={hasModificationAccess === true ? table : tableRead}
              id="objectId"
              name="objectId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteClassBatchMapping}
              // rowsPerPageCount={
              //   data?.getClassBatchMapping?.length > 0
              //     ? data?.getClassBatchMapping?.length
              //     : 10
              // }
              rowsPerPageCount={10}
              style="false"
              totlaRecords={0}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data?.getClassBatchMapping?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
