import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CLASS_LIST } from "utils/graphql/services/entities/queries";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import { table, tableRead } from "./helper";
import NoDataFound from "shared/molecules/no-data";
import { ClassFilter } from "./filter";
import { DELETE_CLASS } from "utils/graphql/services/entities/mutations";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getClassListData } from "utils/metadata-split/metaDataApiFetch";
import { academicTagsUser, checkFeatureAccess } from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function ClassEntitie() {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [getClass, { loading, error, data }] = useLazyQuery(GET_CLASS_LIST, {
    client: entitesClient,
  });

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const [deleteClass, { loading: loadingD, error: errorD, data: dataD }] =
    useMutation(DELETE_CLASS, {
      client: entitesClient, // Ensure you're using the correct GraphQL client instance
    });

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateClassCourseListDate = async () => {
    try {
      const call1: any = await getClassListData(updateMetaData);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (objectId,isArchieve,isDummy) => {
    // const { objectId } = obj;
    history.push(`/entities/class/edit?objectId=${objectId}&isArchived=${isArchieve}&isDummy=${isDummy}`);
  };

  const handleDeleteClass = async (objectId) => {
    try {
      const response: any = await deleteClass({
        variables: { objectId },
      });

      if (response?.data?.deleteClass?.success) {
        console.log(
          "Class deleted successfully:",
          response?.data?.deleteClass?.message
        );
        dispatch(snackbarRequest({ message: "Class deleted successfully" }));
        await handleUpdateClassCourseListDate();
        getClass({ variables: { filter: { limit: 10, offset: 0 } } });
        setLimit(10);
        setOffset(0);
      } else {
        console.log(
          "Class deletion failed:",
          response?.data?.deleteClass?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error deleting class:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    getClass({ variables: { filter: { ...searchPayload, offset, limit } } });
    //getClass({ variables: { filter: {...searchPayload} } });
  }, [searchPayload, offset]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  return (
    <div>
       {hasModificationAccess && (<ContentRight>
        <Link to="class/create" className={classes.label}>
          <Button variant="contained">Create Class</Button>
        </Link>
      </ContentRight>)}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        Class
      </TypographyHeading>
      <ClassFilter setSearchPayload={setSearchPayload} setOffset={setOffset} />
      <Paper elevation={2} sx={{ px: 2, pb: 4, pt: 2, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && data?.getClass.length > 0 ? (
            <SectionTable
              data={data?.getClass}
              table={hasModificationAccess===true?table:tableRead}
              id="objectId"
              name="objectId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteClass}
              rowsPerPageCount={
                data?.getClass?.length > 0 ? data?.getClass?.length : 10
              }
              style="false"
              totlaRecords={0}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            // <CollapsibleTable
            //   data={data?.getClass}
            //   table={table}
            //   id="objectId"
            //   name="objectId"
            //   handleEdit={handleEdit}
            //   handleDelete={handleDeleteClass}
            //   rowsPerPageCount={data?.getClass?.length > 0 ? data?.getClass?.length : 10}
            //   style="false"
            //   totlaRecords={0}
            //   paginationSegmentApi="false"
            //  hasPageApiCall={false}
            //  showPaginationFooter={false}
            // />
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data?.getClass?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
