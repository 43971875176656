import Tooltip from "@mui/material/Tooltip";
import { ActionButtons } from "./action-buttons";
import { MultiAcitonButtons } from "shared/molecules/multi-action-buttons";
import TableCell from "@mui/material/TableCell";
import Eclipse from "shared/molecules/eclipse";
import { DOMAIN } from "utils/helpers";
import { getTime } from "utils/time-util/getTime";
import useStyles from "./styles";
import { Box } from "@mui/system";
import { EditButtonTable } from "./edit-button";

function TableData(props: any) {
  const { data, handleEdit, handleDelete, handleCopy, handlePreview, row, id,displayFullstring } =
    props;
  const styles = useStyles();
  const handlePdfViewer = (data, item) => {
    if (item.value === "pdf_name") {
      let pdfName = data.exam_name || data.batch_name;
      // ? Load pdf in new Tab
      const arr = data.pdf_url || data.url.split("/");
      const bckt = arr[2]?.split(".")[0];
      const rem = data.pdf_url || data.url.split(".amazonaws.com/")[1];
      window.open(
        `${DOMAIN}/pdf-viewer/${bckt}\`~\`${rem}\`~\`${pdfName}\`~\`${
          data.delivery_count || data.week_number || 0
        }`,
        "_blank"
      );
    } else {
      let pdfName = data.assignment_name || data.subject_name || data.exam_name;
      // ? Load pdf in new Tab
      const arr = data[item.value] && data[item.value].split("/");
      const bckt = arr[2]?.split(".")[0];
      const rem =
        data[item.value] && data[item.value].split(".amazonaws.com/")[1];
      window.open(
        `${DOMAIN}/pdf-viewer/${bckt}\`~\`${rem}\`~\`${pdfName}\`~\`${
          data.question_count || 0
        }`,
        "_blank"
      );
    }
  };

  const handleEditIsArchieve=(id,objItem,row)=>{
    if(objItem?.sendIsArchived){
      handleEdit(id,row?.isArchived,row?.isDummy);
    }else{
      handleEdit(id);
    }
  }

  return (
    <>
      {data &&
        data.map((item: any, idx: any) => {
          if (item.title === "ActionEdit") {
            return (
              <TableCell key={idx} width={item.width}>
                <EditButtonTable
                  handleEdit={() => handleEdit(row[id])}
                  hideEdit={typeof handleEdit !== "function"}
                />
              </TableCell>
            );
          }
         else if (item.title === "Actions") {
            return (
              <TableCell key={idx} width={item.width}>
                <ActionButtons
                 // handleEdit={() => handleEdit(row[id])}
                  handleEdit={() => handleEditIsArchieve(row[id],item,row)}
                  handleCopy={() => handleCopy(row[id])}
                  hideCopyIcon={typeof handleCopy !== "function"}
                  handleDelete={() => handleDelete(row[id])}
                  hideDeleteIcon={typeof handleDelete !== "function"}
                />
              </TableCell>
            );
          } else if (item.title === "MutliActions") {
            return (
              <TableCell key={idx} width={item.width}>
                <MultiAcitonButtons
                  test={item}
                  handleEdit={() => handleEdit(row[id])}
                  handleCopy={() => handleCopy(row[id])}
                />
              </TableCell>
            );
          } else if (
            (item.title.includes("Date") ||
            item.value.includes("date")) && !item.name
          ) {
            return (
              <TableCell>{getTime.date(row[item.value], "dd-mm-yy")}</TableCell>
            );
          }else if (item.name) {
            return <TableCell>{row[item.value] || "-"}</TableCell>;
          }  else if (item.value && item.icon && item.inValue) {
            return (
              <TableCell key={row[item.value][item.inValue] + idx}>
                <Tooltip
                  title={item.tooltip ? row[item.value][item.tooltip] : ""}
                >
                  {row[item.value][item.inValue] ? (
                    <a
                      target="_blank"
                      href={row[item.value][item.inValue]}
                      rel="noreferrer"
                      style={{ color: "blue" }}
                    >
                      {item.icon}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (
            item.value &&
            item.icon &&
            !item.inValue &&
            item.fileType === "video"
          ) {
            return (
              <TableCell key={row[item.value] + idx}>
                <Tooltip title={item.tooltip ? row[item.tooltip] : ""}>
                  {row[item.value] ? (
                    <a
                      target="_blank"
                      href={row[item.value]}
                      rel="noreferrer"
                      style={{ color: "blue" }}
                    >
                      {item.icon}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (
            item.value &&
            item.icon &&
            !item.inValue &&
            item.fileType !== "video"
          ) {
            return (
              <TableCell key={row[item.value] + idx}>
                <Tooltip title={item.tooltip ? row[item.tooltip] : ""}>
                  {row[item.value] ? (
                    <div
                      onClick={() => handlePdfViewer(row, item)}
                      style={{ color: "black", cursor: "pointer" }}
                    >
                      {item.icon}
                    </div>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (item.value && !item.icon && item.inValue) {
            return (
              <TableCell key={row[item.value][item.inValue] + idx}>
                <Eclipse
                  item={
                    row[item.value][item.inValue]
                      ? row[item.value][item.inValue].toString()
                      : "-"
                  }
                />
              </TableCell>
            );
          } else if (item.value && !item.icon && !item.inValue) {
            return (
              <TableCell key={`${Math.random()}-${idx}`}>
                {typeof row[item.value] === "boolean" ? (
                  String(row[item.value])
                ) : (
                  <Eclipse
                    item={
                      Array.isArray(row[item.value])
                        ? row[item.value] && row[item.value].length > 0
                          ? String(row[item.value] || "-")
                          : "-"
                        : row[item.value]
                        ? row[item.value].toString() ||
                          String(row[item.value] || "-")
                        : "-"
                    }
                    displayString={
                      Array.isArray(row[item.value]) ? false : true
                    }
                    displayFullstring={displayFullstring}
                    isConfigueType={item?.type=="configue"?true:false}
                  />
                )}
              </TableCell>
            );
          } else if (item.title == "" && item.icon) {
            return (
              <TableCell key={idx} width={item.width}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePreview(row[id])}
                >
                  {item.icon}
                </div>
              </TableCell>
            );
          } 
          else if (item.title == "" && item.icon1 && item.icon2) {
            {console.log("item check",item)}
            return (
              <TableCell key={idx} width={item.width}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  // onClick={() => handlePreview(row[id])}
                >
                  <p >{item.icon1}</p>
                  <p style={{marginLeft:"20px"}}>{item.icon2}</p>
                </Box>
              </TableCell>
            );
          } 
          
          
          
          else {
            <TableCell>No Data</TableCell>;
          }
        })}
    </>
  );
}

export default TableData;
