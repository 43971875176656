import * as Yup from "yup";

export const table = {
    primaryData: [
      {
        title: "sbdBatchName",
        value: "sbdBatchName",
        type: "configue",
      },
      {
        title: "sbdBatchId",
        value: "sbdBatchId",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [],
  };
export const tableRead = {
    primaryData: [
      {
        title: "sbdBatchName",
        value: "sbdBatchName",
        type: "configue",
      },
      {
        title: "sbdBatchId",
        value: "sbdBatchId",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      } 
    ],
    secondaryData: [],
  };

  export type actualSbdBatch = {
    sbdBatchId: string;
    sbdBatchName: string;
};

export const actualSbdBatchInitialValues: actualSbdBatch = {
    sbdBatchId: "",
    sbdBatchName: "",

};

export const actualSbdBatchMandatoryFields = [
  { id: "sbdBatchId" },
  { id: "sbdBatchName" }
];

export const actualSbdBatchValidationSchema = Yup.object().shape({
  sbdBatchId: Yup.string().required("Sbd Batch Id is required"),
  sbdBatchName: Yup.string().required("Sbd Batch Name is required"),
});

export const actualSbdBatchProperties = [
    {
        id: "sbdBatchId",
        type: "text",
        label: `sbdBatchId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "sbdBatchName",
        type: "text",
        label: `sbdBatchName`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
];
export const actualSbdBatchPropertiesUpdate = [
    {
        id: "sbdBatchId",
        type: "text",
        label: `sbdBatchId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "sbdBatchName",
        type: "text",
        label: `sbdBatchName`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "isArchived",
        type: "normal-select",
        label: "IsArchived",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
];
