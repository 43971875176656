import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  actualSbdBatchInitialValues,
  actualSbdBatchMandatoryFields,
  actualSbdBatchProperties,
  actualSbdBatchPropertiesUpdate,
  actualSbdBatchValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_ACTUAL_SBD_BATCH,
  UPDATE_ACTUAL_SBD_BATCH,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { GET_ACTUALSBDBATCH_LIST } from "utils/graphql/services/entities/queries";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { areKeysFilled } from "pages/entities/helper";

export const ActualSbdBatchUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    actualSbdBatchInitialValues,
    actualSbdBatchValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);

  const [getActualSbdBatch, { loading, error, data }] = useLazyQuery(
    GET_ACTUALSBDBATCH_LIST,
    {
      client: entitesClient,
    }
  );

  const [
    updateActualSbdBatch,
    { loading: loadingUp, error: errorUp, data: dataUp },
  ] = useMutation(UPDATE_ACTUAL_SBD_BATCH, {
    client: entitesClient,
  });

  const handleUpdateActualSbdBatch = async () => {
    try {
      const response: any = await updateActualSbdBatch({
        variables: {
          input: {
            id: +objectId,
            sbdBatchId: watch()?.sbdBatchId,
            sbdBatchName: watch()?.sbdBatchName,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateActualSbdBatch?.success) {
        console.log(
          "Batch updated successfully:",
          response?.data?.updateActualSbdBatch?.message
        );
        dispatch(
          snackbarRequest({ message: "Updated actualSbdBatch successfully" })
        );
        history.replace(`/entities/actual_sbd_batch`);
      } else {
        console.log(
          "Batch update failed:",
          response?.data?.updateActualSbdBatch?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating batch:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    // sbdBatchId: "",
    // sbdBatchName: "",
    if (data?.getActualSbdBatch) {
      const { getActualSbdBatch } = data;
      if (!isEmpty(getActualSbdBatch[0])) {
        setValue("sbdBatchId", getActualSbdBatch[0]?.sbdBatchId);
        setValue("sbdBatchName", getActualSbdBatch[0]?.sbdBatchName);
        setValue(
          "isArchived",
          getActualSbdBatch[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);

  useEffect(() => {
    getActualSbdBatch({
      variables: {
        filter: {
          id: +objectId,
          isArchived: isArchived === "true" ? true : false,
        },
      },
    });
  }, []);

  return (
    <div>
      <TypographyHeading>Update Actual Sbd Batch</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={actualSbdBatchPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "220px",
                height: "40px",
                marginTop: "15px",
                marginRight: "0px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateActualSbdBatch}
            //  disabled={!isFieldsValidate(errors, actualSbdBatchMandatoryFields, getValues)}
              disabled={enableButton(watch())}
              >
              Update Actual Sbd Batch
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

const enableButton=(obj)=>{
  if(isEmpty(obj?.sbdBatchId) || isEmpty(obj?.sbdBatchName)){
    return true
  }else{
    return false
  }
}