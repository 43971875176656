import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  classBatchMapInitialValues,
  classBatchMapMandatoryFields,
  classBatchMapProperties,
  classBatchMapValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import { CREATE_CLASS_BATCH_MAPPING } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { getClassListData } from "utils/metadata-split/metaDataApiFetch";

export const ClassBatchMapCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    classBatchMapInitialValues,
    classBatchMapValidationSchema,
    ""
  );
  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [createClassBatchMapping, { loading, error, data }] = useMutation(
    CREATE_CLASS_BATCH_MAPPING,
    {
      client: entitesClient,
    }
  );

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleCreateClassBatchMapping = async () => {
    try {
      const response: any = await createClassBatchMapping({
        variables: {
          input: {
            Batch: watch()?.Batch,
            Class: watch()?.Class,
          },
        },
      });

      if (response?.data?.createClassBatchMapping) {
        console.log(
          "Class-Batch Mapping created:",
          response?.data?.createClassBatchMapping
        );
        dispatch(snackbarRequest({ message: "Created ClassBatchMap successfully" }));
        history.replace(`/entities/class_batch_map`);
      } else {
        console.log("Class-Batch Mapping creation failed");
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err:any) {
      console.error("Error creating Class-Batch Mapping:", err);
      dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
    }
  };

  //  useEffect(()=>{
  //   return ()=>{
  //     updateMetaData([],"batch_list");
  //   }
  //  },[])
 
  return (
    <div>
      <TypographyHeading>Create Class Batch Map</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={classBatchMapProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "220px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleCreateClassBatchMapping}
              disabled={!isFieldsValidate(errors, classBatchMapMandatoryFields, getValues)}
              >
              Create Class Batch Map
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
