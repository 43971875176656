import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  stateCreateInitialValues,
  stateCreateMandatoryFields,
  stateCreateProperties,
  stateCreateValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import { CREATE_STATE } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getStateListData } from "utils/metadata-split/metaDataApiFetch";

export const StateCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    stateCreateInitialValues,
    stateCreateValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();


const [createState, { loading, error, data }] = useMutation(CREATE_STATE, {
  client:entitesClient , // Ensure you're using the correct GraphQL client instance
});

const { isLoading, data: metaData }: any = useAppSelector(
  (state) => state.metadataReducer
);

const updateMetaData = (listData, metaKey) => {
  let metadataList = { ...metaData };
  metadataList[metaKey] = listData;
  dispatch(metadataSuccess(metadataList));
};

const handleUpdateStateListDate = async () => {
 try {
  const call1:any= await getStateListData(updateMetaData);
   updateMetaData(call1[0], call1[1]);
 } catch (error) {
   console.log(error);
 }
};

const handleCreateState = async () => {
  try {
    const response:any = await createState({
      variables: {
        input: {
          name: watch()?.name,
          sbdStateId: +watch()?.sbdStateId,
        },
      },
    });

    if (!isEmpty(response?.data?.createState)) {
      console.log("State created:", response?.data?.createState);
      dispatch(snackbarRequest({ message: "Created State Successfully" }));
      await handleUpdateStateListDate();
      history.replace(`/entities/state`);
    } else {
      console.log("State creation failed");
      dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));
    }
  } catch (err:any) {
    console.error("Error creating state:", err);
    dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
  }
};
console.log("watch",watch());
  return (
    <div>
      <TypographyHeading>Create State</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={stateCreateProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "0px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleCreateState}
               disabled={!isFieldsValidate(errors, stateCreateMandatoryFields, getValues)}
               >
              Create State
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
