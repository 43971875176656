import * as Yup from "yup";

export const table = {
    primaryData: [
      {
        title: "Class",
        value: "name",
        type: "configue",
      },
      {
        title: "Synonyms",
        value: "synonyms",
        type: "configue",
      },
      {
        title: "SbdClassId",
        value: "sbdClassId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [
    ],
  };
export const tableRead = {
    primaryData: [
      {
        title: "Class",
        value: "name",
        type: "configue",
      },
      {
        title: "Synonyms",
        value: "synonyms",
        type: "configue",
      },
      {
        title: "SbdClassId",
        value: "sbdClassId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      }
    ],
    secondaryData: [
    ],
  };

  export type classCreate = {
    name: string;
    synonyms: string;
    sbdClassId: string;
    isDummy:Boolean|string;
};

export const classCreateInitialValues: classCreate = {
    name: "",
    synonyms: "",
    sbdClassId: "",
    isDummy:"false"
};

export const classCreateMandatoryFields = [
  { id: "name" },
  // { id: "synonyms" },
  { id: "sbdClassId" }
];

export const classCreateValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sbdClassId: Yup.string().required("Sbd ClassId is required"),
});

export const classCreateProperties = [
    {
        id: "name",
        type: "text",
        label: `Name`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
    {
        id: "synonyms",
        // type: "text",
        type: "text-area",
        label: `Synonyms`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: false,
      },
      {
        id: "sbdClassId",
        type: "text",
        label: `sbdClassId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
];
export const classUpdateProperties = [
    {
        id: "name",
        type: "text",
        label: `Name`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
    {
        id: "synonyms",
        // type: "text",
        type: "text-area",
        label: `Synonyms`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: false,
      },
      {
        id: "sbdClassId",
        type: "text",
        label: `sbdClassId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "isDummy",
        type: "normal-select",
        label: "isDummy",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
      {
        id: "isArchived",
        type: "normal-select",
        label: "IsArchived",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
];


export const handleDisableCreate = (obj) => {
  const { name, sbdClassId } = obj;

  if (
    typeof name === "string" &&
    name?.length > 0 &&
    typeof sbdClassId === "string" &&
    sbdClassId?.length > 0
  ) {
    return false;
  } else {
    return true;
  }
};
