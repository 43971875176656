import { gql } from "@apollo/client";


export const CREATE_BATCH = gql`
  mutation CreateBatch($input: CreateBatchInput!) {
    createBatch(input: $input) {
      objectId
      name
      createdAt
      metadataJson
      metadata {
        isDigital
        isPracticeDisabled
        isDoubtSolvingDisabled
        isDigitalNarayanaEnabled
        downloadstatsViewStatus
        isJeeBatch
        isLearnDisabled
        asPerCourseColumn
        sectionNameToBeOverridden
        isRevisenowNotificationDisabled
      }
    }
  }
`;

 export const CREATE_BRANCH = gql`
  mutation CreateBranch($input: CreateBranchInput!) {
    createBranch(input: $input) {
      objectId
      name
      District
      State
      createdAt
    }
  }
`;

 export const CREATE_YEAR = gql`
  mutation CreateYear($input: CreateYearInput!) {
    createYear(input: $input) {
      objectId
      createdAt
      updatedAt
      name
      academicYear
      yearId
      isUpcoming
      sbdYearId
      metadataJson
      metadata {
        downloadstatsViewStatus
      }
    }
  }
`;

export const CREATE_CLASS = gql`
  mutation CreateClass($input: CreateClassInput!) {
    createClass(input: $input) {
      objectId
      name
      createdAt
    }
  }
`;

export const CREATE_STATE = gql`
mutation CreateState($input: CreateStateInput!) {
  createState(input: $input) {
    objectId
    name
    createdAt
  }
}
`;

export const CREATE_DISTRICT = gql`
  mutation CreateDistrict($input: CreateDistrictInput!) {
    createDistrict(input: $input) {
      objectId
      name
      createdAt
    }
  }
`;

export const CREATE_ACTUAL_SBD_BATCH = gql`
  mutation CreateActualSbdBatch($input: CreateActualSbdBatchInput!) {
    createActualSbdBatch(input: $input) {
      sbdBatchId
      sbdBatchName
      createdAt
    }
  }
`;

export const CREATE_ACTUAL_SBD_PROGRAM = gql`
  mutation CreateActualSbdProgram($input: CreateActualSbdProgramInput!) {
    createActualSbdProgram(input: $input) {
      id
      sbdProgramId
      sbdProgramName
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CLASS_BATCH_MAPPING = gql`
  mutation CreateClassBatchMapping($input: CreateClassBatchMappingInput!) {
    createClassBatchMapping(input: $input) {
      objectId
      Batch
      Class
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_YEAR = gql`
  mutation deleteYear($objectId: String!) {
    deleteYear(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_BATCH = gql`
  mutation deleteBatch($objectId: String!) {
    deleteBatch(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_BRANCH = gql`
  mutation deleteBranch($objectId: String!) {
    deleteBranch(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_CLASS = gql`
  mutation deleteClass($objectId: String!) {
    deleteClass(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_STATE = gql`
  mutation DeleteState($objectId: String!) {
    deleteState(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_DISTRICT = gql`
  mutation DeleteDistrict($objectId: String!) {
    deleteDistrict(objectId: $objectId) {
      success
      message
    }
  }
`;

export const DELETE_ACTUAL_SBD_BATCH = gql`
  mutation deleteActualSbdBatch($id: Int!) {
    deleteActualSbdBatch(id: $id) {
      success
      message
    }
  }
`;

export const DELETE_ACTUAL_SBD_PROGRAM = gql`
  mutation DeleteActualSbdProgram($id: Int!) {
    deleteActualSbdProgram(id: $id) {
      success
      message
    }
  }
`;

export const DELETE_CLASS_BATCH_MAPPING = gql`
  mutation DeleteClassBatchMapping($objectId: String!) {
    deleteClassBatchMapping(objectId: $objectId) {
      success
      message
    }
  }
`;

export const UPDATE_YEAR = gql`
  mutation UpdateYear($input: UpdateYearInput!) {
    updateYear(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_BATCH = gql`
  mutation UpdateBatch($input: UpdateBatchInput!) {
    updateBatch(input: $input) {
      success
      message
    }
  }
`;
 
export const UPDATE_BRANCH = gql`
  mutation UpdateBranch($input: UpdateBranchInput!) {
    updateBranch(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation UpdateClass($input: UpdateClassInput!) {
    updateClass(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_STATE = gql`
  mutation UpdateState($input: UpdateStateInput!) {
    updateState(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_DISTRICT = gql`
  mutation UpdateDistrict($input: UpdateDistrictInput!) {
    updateDistrict(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_ACTUAL_SBD_BATCH = gql`
  mutation UpdateActualSbdBatch($input: UpdateActualSbdBatchInput!) {
    updateActualSbdBatch(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_ACTUAL_SBD_PROGRAM = gql`
  mutation UpdateActualSbdProgram($input: UpdateActualSbdProgramInput!) {
    updateActualSbdProgram(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_CLASS_BATCH_MAPPING = gql`
  mutation UpdateClassBatchMapping($input: UpdateClassBatchMappingInput!) {
    updateClassBatchMapping(input: $input) {
      success
      message
    }
  }
`;