import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  actualSbdBatchInitialValues,
  actualSbdBatchMandatoryFields,
  actualSbdBatchProperties,
  actualSbdBatchValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import { CREATE_ACTUAL_SBD_BATCH } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { areKeysFilled } from "pages/entities/helper";

export const ActualSbdBatchCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    actualSbdBatchInitialValues,
    actualSbdBatchValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [createActualSbdBatch, { loading, error, data }] = useMutation(CREATE_ACTUAL_SBD_BATCH, {
    client: entitesClient, // Ensure you're using the correct GraphQL client instance
  });
  
  const handleCreateActualSbdBatch = async () => {
    try {
      const response:any = await createActualSbdBatch({
        variables: {
          input: {
            sbdBatchId: watch()?.sbdBatchId,
            sbdBatchName: watch()?.sbdBatchName,
          },
        },
      });
  
      if (!isEmpty(response?.data?.createActualSbdBatch)) {
        console.log("SbdBatch created:", response?.data?.createActualSbdBatch);
        dispatch(snackbarRequest({ message: "Created actualSbdBatch successfully" }));
        history.replace(`/entities/actual_sbd_batch`);
      } else {
        console.log("SbdBatch creation failed");
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));
      }
    } catch (err:any) {
      console.error("Error creating SbdBatch:", err);
      dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
    }
  };

  return (
    <div>
      <TypographyHeading>Create Actual Sbd Batch</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={actualSbdBatchProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "220px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleCreateActualSbdBatch}
              // disabled={!isFieldsValidate(errors, actualSbdBatchMandatoryFields, getValues)}
              disabled={enableButton(watch())}
            >
              Create Actual Sbd Batch
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

const enableButton=(obj)=>{
  if(isEmpty(obj?.sbdBatchId) || isEmpty(obj?.sbdBatchName)){
    return true
  }else{
    return false
  }
}
