import * as Yup from "yup";

export const table = {
  primaryData: [
    {
      title: "District",
      value: "name",
      type: "configue",
    },
    {
      title: "SbdDistrictId",
      value: "sbdDistrictId",
    },
    {
      title: "State",
      value: "State",
      type: "configue",
    },
    {
      title: "SbdStateId",
      value: "sbdStateId",
    },
    {
      title: "IsDummy",
      value: "isDummy",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    },
    {
      title: "Actions",
      width: "5%",
      sendIsArchived:true
    },
  ],
  secondaryData: [
  ],
};
export const tableRead = {
  primaryData: [
    {
      title: "District",
      value: "name",
      type: "configue",
    },
    {
      title: "SbdDistrictId",
      value: "sbdDistrictId",
    },
    {
      title: "State",
      value: "State",
      type: "configue",
    },
    {
      title: "SbdStateId",
      value: "sbdStateId",
    },
    {
      title: "IsDummy",
      value: "isDummy",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    }
  ],
  secondaryData: [
  ],
};


export type district = {
  name: string;
  state: string;
  sbdStateId: string;
  sbdDistrictId: string;
  isDummy: Boolean | string;
};

export const districtInitialValues: district = {
  name: "",
  state: "",
  sbdStateId: "",
  sbdDistrictId: "",
  isDummy: "false",
};

export const districtMandatoryFields = [
  { id: "name" },
  { id: "state" },
  // { id: "sbdStateId" },
  { id: "sbdDistrictId" },
];

export const districtValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  state: Yup.string().required("state is required"),
  sbdDistrictId: Yup.number()
    .required("SBD District ID is required")
    .typeError("SBD District ID must be a number")
    .integer("SBD District ID must be an integer"),
});

export const districtProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "state",
    type: "single-select-dependency",
    label: "State *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: [""],
    dependentFlds: {
      state_list: "state_list",
    },
  },
  {
    id: "sbdDistrictId",
    type: "text",
    label: `sbdDistrictId`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
];
export const districtPropertiesUpdate = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "state",
    type: "single-select-dependency",
    label: "State",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: [""],
    dependentFlds: {
      state_list: "state_list",
    },
  },
  {
    id: "sbdDistrictId",
    type: "text",
    label: `sbdDistrictId`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "isDummy",
    type: "normal-select",
    label: "isDummy",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];
