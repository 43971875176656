import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  classBatchMapInitialValues,
  classBatchMapMandatoryFields,
  classBatchMapProperties,
  classBatchMapPropertiesUpdate,
  classBatchMapValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CLASS_BATCH_MAPPING,
  UPDATE_CLASS_BATCH_MAPPING,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { GET_CLASSBATCHMAPPING_LIST } from "utils/graphql/services/entities/queries";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useEffect } from "react";
import { getQueryParam, isEmpty } from "utils/helpers";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";

export const ClassBatchMapUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    classBatchMapInitialValues,
    classBatchMapValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);

  const [getClassBatchMapping, { loading, error, data }] = useLazyQuery(
    GET_CLASSBATCHMAPPING_LIST,
    {
      client: entitesClient,
    }
  );

  const [
    updateClassBatchMapping,
    { loading: loadingUp, error: errorUp, data: dataUp },
  ] = useMutation(UPDATE_CLASS_BATCH_MAPPING, {
    client: entitesClient,
  });
  

  const handleUpdateClassBatchMapping = async () => {
    try {
      const response: any = await updateClassBatchMapping({
        variables: {
          input: {
            objectId:objectId,
            Batch: watch()?.Batch,
            Class: watch()?.Class,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateClassBatchMapping?.success) {
        dispatch(snackbarRequest({ message: "Updated ClassBatchMap successfully" }));
        history.replace(`/entities/class_batch_map`);
      } else {
        console.log(
          "Class-Batch Mapping update failed:",
          response?.data?.updateClassBatchMapping?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating Class-Batch Mapping:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    if (data?.getClassBatchMapping) {
      const { getClassBatchMapping } = data;
      if (!isEmpty(getClassBatchMapping[0])) {
        setValue("Class", getClassBatchMapping[0]?.Class);
        setValue("Batch", getClassBatchMapping[0]?.Batch);
        setValue(
          "isArchived",
          getClassBatchMapping[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);

  useEffect(() => {
    getClassBatchMapping({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
        },
      },
    });
  }, []);

  return (
    <div>
      <TypographyHeading>Update Class Batch Map</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={classBatchMapPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "220px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateClassBatchMapping}
              disabled={!isFieldsValidate(errors, classBatchMapMandatoryFields, getValues)}
            >
              Update Class Batch Map
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
