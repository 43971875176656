import React from "react";
import EditIcon from "@mui/icons-material/EditOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CustomPopper } from "shared/organisms/popper/custom-popper";
import { Stack, Tooltip } from "@mui/material";

type ButtonProps = {
  handleEdit?: () => void;
  handleCopy?: () => void;
  hideDeleteIcon:Boolean;
  hideCopyIcon:Boolean;
  handleDelete?: () => void;
};

export const ActionButtons = (props: ButtonProps) => {
  const { handleEdit, handleCopy,hideDeleteIcon,handleDelete,hideCopyIcon } = props;
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      {hideCopyIcon !== true &&
        (<Tooltip title="Duplicate Delivery">
          <ContentCopyIcon onClick={handleCopy} />
        </Tooltip>)
      }
      {
        <Tooltip title="Edit Delivery">
          <EditIcon onClick={handleEdit} />
        </Tooltip>
      }
       {hideDeleteIcon !== true && (
        <CustomPopper onAgree={handleDelete} sx={{}}>
          <Tooltip title="Delete">
            <DeleteIcon />
          </Tooltip>
        </CustomPopper>
      )}
    </Stack>
  );
};
