import { Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import configueService from "pages/configue/service";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createProgramInitialValues,
  createProgramValidationSchema,
  createProgramProperties,
  createProgramMandatoryFields,
} from "./helper";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isEmpty } from "utils/helpers";
import LoadingButton from "@mui/lab/LoadingButton";

export const UpdateProgram = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );
  const { data, error, loading, updateProgramDetails } = useAppSelector(
    (state) => state.createProgramSlice
  );
  const [btnLoading,setBtnLoading]=useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleUpdateProgram = async () => {
    setBtnLoading(true)
    const namePl = watch()?.name?.trim();
    
    if (isEmpty(namePl)) {
      dispatch(
        snackbarRequest({ message: "Please check/fill name", type: "error" })
      );
      
      setBtnLoading(false);
      return;
    }
    const reqBody = {
      name: namePl,
      program_id: updateProgramDetails?.program_id,
    };
    const res: any = await configueService.updateProgramProperties(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "updated successfully" }));
      setBtnLoading(false);
      history.replace(`/configue/create_program`);
    } else {
      setBtnLoading(false);
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  useEffect(() => {
    if (isEmpty(updateProgramDetails)) {
      history.replace(`/configue/create_program`);
      //to handle refresh we dont have separate api to get singleprogram details with id
    } else {
      setValue("name", updateProgramDetails?.program_name);
    }
  }, [updateProgramDetails]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Update Program
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createProgramProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              color="primary"
              onClick={handleUpdateProgram}
              loading={btnLoading}
              style={{
                width: "120px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              // disabled={
              //   !isFieldsValidate(
              //     errors,
              //     createProgramMandatoryFields,
              //     getValues
              //   )
              // }
              disabled={
                isEmpty(watch()?.name)
               }
            >
              Update
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
