import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Box, Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { branchFilterInitialValues, branchFilterInitialValuesSearch, branchFilterProperties, branchFilterPropertiesSearch, branchFilterValidationSchema, branchFilterValidationSchemaSearch } from "./helper";
import { formatDate } from "../../state/filter/helper";
import { hasAtLeastOneValue, hasLengthGreaterThanZero, hasValidValue, hasValidValueIgnore } from "pages/entities/helper";
import { useEffect } from "react";
import { metadataSuccess } from "app-component/slices/metadata-slice";

import { makeStyles } from "@mui/styles";
import { isEmpty } from "utils/helpers";

export const useStyles = makeStyles({
    orButton: {
        backgroundColor: "#3453b4",
        borderRadius: "30px",
        height: "32px",
        width: "32px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: '15px',
        color: "white",
        fontSize: "14px"
    },
    prevBtn:{
        backgroundColor:"#3f50b5",
        color:"white"
    },
    BtnWrapper:{
        display: "flex",
        gap: "20px",
        justifyContent:"flex-end",
        paddingTop:"10px"
    
    }
});

export const BranchFilter = ({setSearchPayload ,setOffset }) => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    branchFilterInitialValues,
    branchFilterValidationSchema,
    ""
  );
  const classes = useStyles();
  const { control:controlS, errors:errorsS, getValues:getValuesS, watch:watchS, setValue:setValueS } = useReactHookForm(
    branchFilterInitialValuesSearch,
    branchFilterValidationSchemaSearch,
    ""
  );
  const dispatch = useAppDispatch();
  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const clearFilter=()=>{
    setValue("state_list","");
    setValue("branch_list","");
    setValueS("branchName","");
    setValue("start_date","");
    setValue("end_date","");
    setValue("isArchived","false");
    setValue("isDummy","false");
    setSearchPayload({});
    setOffset(0);
  }

  const handleSearch = () => {
    const payload = {
      name:
        watch()?.branch_list?.length > 0
          ? watch()?.branch_list
          : watchS()?.branchName,
      createdFromDate: formatDate(watch()?.start_date),
      createdToDate: watch()?.end_date,
      isArchived: watch()?.isArchived === "true" ? true : false,
      isDummy:watch()?.isDummy==="true"?true:false,
    };
    setSearchPayload(payload);
    setOffset(0);
  };

  useEffect(() => {
    if (watch()?.state_list == "") {
      updateMetaData([], "branch_list");
    } else if (watch()?.state_list?.length) {
      setValueS("branchName", "");
    }
  }, [watch()?.state_list]);

  useEffect(() => {
    if (watchS()?.branchName?.length > 0) {
      setValue("state_list", "");
      setValue("branch_list","");
      updateMetaData([], "branch_list");
    }
  }, [watchS()?.branchName]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={branchFilterPropertiesSearch}
            control={controlS}
            errors={errorsS}
            getValues={getValuesS}
            setValue={setValueS}
            watch={watchS}
          />
           <Box className={classes.orButton}>OR</Box>
          <DynamicForm
            fields={branchFilterProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <Button
              variant="contained"
              sx={{
                width: "70px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
             onClick={clearFilter}
             disabled={disableBtn(watch(),isEmpty(watchS()?.branchName))}
             >
              clear
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
                onClick={handleSearch}
                //disabled={!hasValidValueIgnore(watch(), "isArchived","state_list")}
                disabled={disableBtn(watch(),isEmpty(watchS()?.branchName))}
                >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

export const disableBtn = (val: any, val2: any) => {
  if (!val2) {
    return false;
  } else {
    return !hasValidValueIgnore(val, "isArchived", "state_list","isDummy");
  }
};