import * as Yup from "yup";
import { AssignmentFieldTypes } from "pages/assignments/interfaces";
import {
  urlValidationOptional,
  nameValidationOptional,
  nameValidationRequired,
} from "utils/form-validation";

export const mandatoryFields = [
  { id: "class_id" },
  { id: "subject_id" },
  { id: "question_count" },
  { id: "duration" },
  { id: "assignment_name" },
  { id: "assignment_pdf_url" },
  { id: "type" },
];

export const interactiveScrollsmandatoryFields = [
  { id: "class_id" },
  { id: "subject_id" },
  { id: "question_count" },
  { id: "duration" },
  { id: "assignment_name" },
  { id: "assignment_pdf_url" },
  { id: "type" },
  { id: "page_count" },
];

export const listeningComprehensionMandatoryFields = [
  { id: "class_id" },
  { id: "subject_id" },
  { id: "question_count" },
  { id: "duration" },
  { id: "assignment_name" },
  { id: "assignment_pdf_url" },
  { id: "type" },
  { id: "audio_file_name" },
  { id: "audio_file_url" },
];

export const liveworksheetMandatoryFields = [
  { id: "class_id" },
  { id: "subject_id" },
  { id: "type" },
  { id: "assignment_name" },
  { id: "liveworksheet_id" },
  { id: "liveworksheet_teacher_code" },
  { id: "assignment_pdf_url" },
];

export const assignmentInitialValues: AssignmentFieldTypes = {
  class_id: "",
  subject_id: "",
  question_count: "",
  duration: "",
  assignment_name: "",
  assignment_pdf_url: "",
  solution_pdf_name: "",
  solution_pdf_url: "",
  solution_video_name: "",
  solution_video_url: "",
  type: "",
  page_count: "",
  audio_file_name: "",
  audio_file_url: "",
  liveworksheet_id:"",
  liveworksheet_teacher_code:""
};

export const validationSchema = (pageType) => {
  return Yup.object().shape({
    type: Yup.string().required("Type required"),
    class_id: Yup.string().required("Class required"),
    subject_id: Yup.string().required("subject required"),
    question_count: Yup.number()
      .typeError("Number of Questions are required")
      .max(180, "max length 180")
      .min(1, "Invalid Number")
      .integer("Invalid Number"),
    duration: Yup.number()
      .typeError("Duration is required")
      .max(180, "max length 180")
      .min(1, "Invalid Number")
      .integer("Invalid Number"),
    page_count: Yup.number()
      .typeError("Page Count is required")
      .max(7, "max length 7")
      .min(1, "Invalid Number")
      .integer("Invalid Number"),
    assignment_name: Yup.string()
      .required(`${pageType} Name required`)
      .min(2, "The name is too short")
      .matches(nameValidationRequired, "Invalid name format")
      .max(100, "The name is too long"),
    liveworksheet_id: Yup.string()
      .required(`worksheet Id required`)
      .min(7, "worksheet Id is too short")
      .matches(nameValidationRequired, "Invalid name format")
      .max(100, "worksheet Id is too long"),
    liveworksheet_teacher_code: Yup.string()
      .required(`teacher Id required`)
      .min(10, "teacher Id is too short")
      .matches(nameValidationRequired, "Invalid teacher Id format")
      .max(100, "teacher Id is too long"),
    solution_pdf_name: Yup.string()
      .nullable()
      .notRequired()
      .matches(nameValidationOptional, "Invalid name format")
      .max(100, "The name is too long"),
    solution_video_name: Yup.string()
      .nullable()
      .notRequired()
      .matches(nameValidationOptional, "Invalid name format")
      .max(100, "The name is too long"),
    solution_video_url: Yup.string().matches(urlValidationOptional, ""),
    audio_file_name: Yup.string()
      .required(`Audio File Name required`)
      .min(2, "The name is too short")
      .matches(nameValidationRequired, "Invalid name format")
      .max(100, "The name is too long"),
  });
};

export const createWithAssignmentProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  },
  {
    id: "question_count",
    type: "number",
    label: "No of Questions *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in minutes *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
];
export const createWithLiveworksheetProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  }
];

export const createWithInteractiveScrollsProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  },
  {
    id: "question_count",
    type: "number",
    label: "No of Activities *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in minutes *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "page_count",
    type: "number",
    label: "Page Count *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
];

export const editWithAssignmentProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    disabled: true,
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  },
  {
    id: "question_count",
    type: "number",
    label: "No of Questions *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in minutes *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
];

export const editWithInteractiveScrollsProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    disabled: true,
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  },
  {
    id: "question_count",
    type: "number",
    label: "No of Activities *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in minutes *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "page_count",
    type: "number",
    label: "Page Count *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
];
export const EditWithLiveworksheetProperties = [
  {
    id: "type",
    type: "normal-select",
    label: "Type *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    options: [],
    disabled: true,
    list: "assignment_type_list",
    keyValue: { label: "assignment_type_name", value: "assignment_type_id" },
  },
  {
    id: "class_id",
    type: "normal-select",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
  {
    id: "subject_id",
    type: "normal-select",
    label: "Subject *",
    className: "select-styles",
    isDependency: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "subject_list",
    keyValue: { label: "subject_name", value: "subject_id" },
  }
];

export const uploadFields = (pageType: any) => {
  return [
    {
      id: "assignment_name",
      type: "text",
      label: `Name of the ${pageType}`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
      required: true,
    },
    {
      id: "assignment_pdf_url",
      type: "upload",
      label: `Upload ${pageType} *`,
      size: 50,
      fields: { class_id: "", subject_id: "", assignment_name: "" },
      feature: "assignments",
      fileType: [".pdf"],
      acceptTypes: ".pdf",
      className: "upload-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
      showError: true,
    },
  ];
};

export const liveWorkSheetUpload=()=>{
  return[
    {
      id: "assignment_name",
      type: "text",
      label: `Name of the Live Worksheet `,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 4 },
      required: true,
    },
    {
      id: "liveworksheet_id",
      type: "text",
      label: `Worksheet ID`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
      required: true,
    },
    {
      id: "liveworksheet_teacher_code",
      type: "text",
      label: `Teacher ID`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
      required: true,
    },
    {
      id: "assignment_pdf_url",
      type: "text",
      label: `Worksheet URL`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
      required: true,
     // disabled: true,
    },
  ]
}

export const solutionPDFFields = (pageType) => {
  return [
    {
      id: "solution_pdf_name",
      type: "text",
      label: `Name of the ${pageType} Solution Pdf`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      id: "solution_pdf_url",
      type: "upload",
      label: `Upload ${pageType} Solution Pdf File`,
      size: 50,
      fields: { class_id: "", subject_id: "", solution_pdf_name: "" },
      feature: "assignments",
      fileType: [".pdf"],
      acceptTypes: ".pdf",
      className: "upload-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
      showError: true,
    },
  ];
};

export const solutionVideoFields = (pageType: any) => {
  return [
    {
      id: "solution_video_name",
      type: "text",
      label: `Name of the ${pageType} Solution Video`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      id: "solution_video_url",
      type: "url",
      label: `Upload ${pageType} Solution Video Url`,
      className: "name-styles",
      size: 1,
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
      showError: true,
    },
  ];
};

export const uploadAudioFields = [
  {
    id: "audio_file_name",
    type: "text",
    label: `Name of the Audio File`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
    required: true,
  },
  {
    id: "audio_file_url",
    type: "upload",
    label: `Upload Audio File*`,
    size: 50,
    fields: { class_id: "", subject_id: "", audio_file_name: "" },
    feature: "assignments",
    fileType: [".mp3", ".mp4"],
    acceptTypes: "audio/mp3,video/mp4",
    className: "upload-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 },
    showError: true,
  },
];

export const getType = (
  type: "assignment" | "interactive_scrolls" | "listening_comprehension" | ""
) => {
  const types = {
    assignment: "Assignment",
    interactive_scrolls: "Interactive Scrolls",
    listening_comprehension: "Listening Comprehension",
    live_worksheet:"Live Worksheet"
  };
  return type === "" ? "Assignment" : types[type];
};
