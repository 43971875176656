import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  stateCreateInitialValues,
  stateCreateMandatoryFields,
  stateCreateProperties,
  stateCreateValidationSchema,
  stateUpdateProperties,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_STATE,
  UPDATE_STATE,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { GET_STATE_LIST } from "utils/graphql/services/entities/queries";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getStateListData } from "utils/metadata-split/metaDataApiFetch";

export const StateUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    stateCreateInitialValues,
    stateCreateValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);
  const isDummy = getQueryParam("isDummy", location);

  const [getStates, { loading, error, data }] = useLazyQuery(GET_STATE_LIST, {
    client: entitesClient,
  });

  const [updateState, { loading: loadingUp, error: errorUp, data: dataUp }] =
    useMutation(UPDATE_STATE, {
      client: entitesClient,
    });

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateStateListDate = async () => {
    try {
      const call1: any = await getStateListData(updateMetaData);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateState = async () => {
    try {
      const response: any = await updateState({
        variables: {
          input: {
            objectId: objectId,
            name: watch()?.name,
            sbdStateId: +watch()?.sbdStateId,
            isDummy: watch()?.isDummy == "true" ? true : false,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateState?.success) {
        console.log(
          "State updated successfully:",
          response?.data?.updateState?.message
        );
        dispatch(snackbarRequest({ message: "updated State Successfully" }));
        await handleUpdateStateListDate();
        history.replace(`/entities/state`);
      } else {
        console.log(
          "State update failed:",
          response?.data?.updateState?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating state:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    if (data?.getState) {
      const { getState } = data;
      if (!isEmpty(getState[0])) {
        setValue("name", getState[0]?.name);
        setValue(
          "sbdStateId",
          getState[0]?.sbdStateId == null ? "" : getState[0]?.sbdStateId
        );
        setValue("isDummy", getState[0]?.isDummy == true ? "true" : "false");
        setValue(
          "isArchived",
          getState[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);

  useEffect(() => {
    getStates({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
          isDummy: isDummy === "true" ? true : false,
        },
      },
    });
  }, []);

  return (
    <div>
      <TypographyHeading>Update State</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={stateUpdateProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -1 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "0px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateState}
              disabled={
                !isFieldsValidate(errors, stateCreateMandatoryFields, getValues)
              }
            >
              update State
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
