import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import Loader from "pages/build-test/loader";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  searchCourseInitialValues,
  searchCourseMandatoryFields,
  searchCourseProperties,
  searchCourseValidationSchema,
  table,
} from "./helper";
import configueService from "pages/configue/service";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { TypographyHeading } from "shared/molecules/typography-layout";
import NoDataFound from "shared/molecules/no-data";
import {
  courseListRequest,
  UpdateSingleCourseDetails,
} from "pages/configue/slices/create-course-slice";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export const CreateCourseMain = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [courseList, setCourseList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchClassId, setSearchClassID] = useState("");

  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    searchCourseInitialValues,
    searchCourseValidationSchema,
    ""
  );
  const { data, error, loading } = useAppSelector(
    (state) => state.createCourseSlice
  );

  const handleEdit = (singleCourse) => {
    if (singleCourse?.class_id.length > 1) {
      dispatch(
        snackbarRequest({
          message: "unable to edit as it contain more than 1 class",
          type: "error",
        })
      );
    } else {
      let course = { ...singleCourse };
      course["class_id"] = singleCourse?.class_id?.[0] || "";
      dispatch(UpdateSingleCourseDetails(course));
      history.push(`/configue/create_course/edit/${singleCourse?.course_id}`);
    }
  };

  const handleClear = () => {
    setValue("class_id", "");
    setOffset(0);
    setSearchClassID("");
  };

  const handleSearch = () => {
    setSearchClassID(watch()?.class_id);
    setOffset(0);
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleCourse = { ...item };
      singleCourse["class_name"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleCourse["class_id"] = takeRequiredValue(
        item?.class_list,
        "class_id"
      );
      return singleCourse;
    });
    setCourseList(tableData);
  };

  useEffect(() => {
    dispatch(courseListRequest({ class_id: searchClassId, limit, offset }));
  }, [limit, offset, searchClassId]);

  useEffect(() => {
    if (data.length > 0) {
      convertDataToTableData();
    } else {
      setCourseList([]);
    }
  }, [data]);

  return (
    <div>
      <ContentRight>
        <Link to="/configue/create_course/create" className={classes.label}>
          <Button variant="contained">Create New Course </Button>
        </Link>
      </ContentRight>
      <TypographyHeading>Course</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 4, mt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={searchCourseProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: -7 }}>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
              marginLeft: "10px",
            }}
            onClick={handleClear}
            disabled={
              !isFieldsValidate(errors, searchCourseMandatoryFields, getValues)
            }
          >
            clear
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
              marginLeft: "10px",
            }}
            onClick={handleSearch}
            disabled={
              !isFieldsValidate(errors, searchCourseMandatoryFields, getValues)
            }
          >
            search
          </Button>
        </ContentRight>
        <Box sx={{ mt: "20px" }}>
          {loading && <Loader />}
          {!loading && courseList.length > 0 && !error ? (
            <div>
              <CollapsibleTable
                data={courseList}
                table={table}
                id="id"
                handleEdit={handleEdit}
                // handleCopy={handleCopy}
                rowsPerPageCount={20}
                paginationSegmentApi="false"
                hasPageApiCall={false}
                showPaginationFooter={false}
              />
            </div>
          ) : (
            <div>
              {!loading && (
                <NoDataFound message="Please Search for Course List" />
              )}
            </div>
          )}
        </Box>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
};
