import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fibValues: [],
};

const fibQuestionsSlice = createSlice({
  name: "fibQuestions",
  initialState,
  reducers: {
    updateFibValues(state, action) {
      state.fibValues = action.payload;
    },
    resetFibValues(state) {
      state.fibValues = [];
    },
  },
});

export const {
    updateFibValues,resetFibValues
} = fibQuestionsSlice.actions;
export default fibQuestionsSlice.reducer;
