
import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export const table = {
    primaryData: [
      {
        title: "Branch",
        value: "name",
        type: "configue",
      },
      {
        title: "State",
        value: "State",
        type: "configue",
      },
      {
        title: "District",
        value: "District",
      },
      {
        title: "sbdCollegeId",
        value: "sbdCollegeId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [
    ],
  };
export const tableRead = {
    primaryData: [
      {
        title: "Branch",
        value: "name",
        type: "configue",
      },
      {
        title: "State",
        value: "State",
        type: "configue",
      },
      {
        title: "District",
        value: "District",
      },
      {
        title: "sbdCollegeId",
        value: "sbdCollegeId",
      },
      {
        title: "IsDummy",
        value: "isDummy",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
    ],
    secondaryData: [
    ],
  };

  export type branch = {
    name: string;
    state: string;
    district: string;
    sbdCollegeId: string;
    sbdStateId:string
    sbdDistrictId:string
    isDummy:Boolean|string;
};

export const branchInitialValues: branch = {
    name: "",
    state: "",
    district: "",
    sbdCollegeId: "",
    sbdStateId:"",
    sbdDistrictId:"",
    isDummy:"false",
};

export const branchMandatoryFields = [
  { id: "name" },
  { id: "state" },
  { id: "district" },
  { id: "sbdCollegeId" },
  // { id: "sbdStateId" },
  // { id: "sbdDistrictId" },
];

export const branchValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  state: Yup.string().required("state is required"),
  district: Yup.string().required("district is required"),
  sbdCollegeId: Yup.string().required("sbdCollegeId is required")
});

export const branchProperties = [
    {
        id: "name",
        type: "text",
        label: `Name`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
      {
        id: "state",
        type: "single-select-dependency",
        label: "State *",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: [],
        list: "state_list",
        keyValue: { label: "state_name", value: "state_id" },
        dependentFields: ["district"],
        dependentFlds: {
          state_list: "state_list",
        },
      },
      {
        id: "district",
        type: "single-select-dependency",
        label: "District *",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: [],
        list: "district_list",
        isDependent: true,
        required: true,
        fieldKeyLists: {},
        hasApiCall: true,
       // endpoint: endpointsMd.GET_COLLEGE_LIST,
        requestList: async (payload) => {
          return { state_id:payload?.state };
        },
        changeValueId: "state",
        endpoint:endpointsMd.GET_DISTRICT_LIST,
        keyValue: { label: "district_name", value: "district_id" },
        dependentFields: [""],
        dependentFlds: {},
        dependencyLabel: "Please select state",
      },
      {
        id: "sbdCollegeId",
        type: "text",
        label: `sbdCcollegeId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
        required: true,
      },
];

export const branchPropertiesUpdate = [
  {
      id: "name",
      type: "text",
      label: `Name`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
      required: true,
    },
    {
      id: "state",
      type: "single-select-dependency",
      label: "State",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      list: "state_list",
      keyValue: { label: "state_name", value: "state_id" },
      dependentFields: ["district"],
      dependentFlds: {
        state_list: "state_list",
      },
    },
    {
      id: "district",
      type: "single-select-dependency",
      label: "District",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      list: "district_list",
      isDependent: true,
      required: true,
      fieldKeyLists: {},
      hasApiCall: true,
     // endpoint: endpointsMd.GET_COLLEGE_LIST,
      requestList: async (payload) => {
        return { state_id:payload?.state };
      },
      changeValueId: "state",
      endpoint:endpointsMd.GET_DISTRICT_LIST,
      keyValue: { label: "district_name", value: "district_id" },
      dependentFields: [""],
      dependentFlds: {},
      dependencyLabel: "Please select state",
    },
    {
      id: "sbdCollegeId",
      type: "text",
      label: `sbdCcollegeId`,
      className: "name-styles",
      breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
      required: true,
    },
    {
      id: "isDummy",
      type: "normal-select",
      label: "isDummy",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      hideNone: true,
    }, 
    {
      id: "isArchived",
      type: "normal-select",
      label: "IsArchived",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      hideNone: true,
    }, 
];