import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  districtInitialValues,
  districtMandatoryFields,
  districtProperties,
  districtPropertiesUpdate,
  districtValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { getQueryParam, isEmpty } from "utils/helpers";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_DISTRICT, UPDATE_DISTRICT } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getValueFromMetadata } from "pages/entities/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import { GET_DISTRICT_LIST } from "utils/graphql/services/entities/queries";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";

export const DistrictUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    districtInitialValues,
    districtValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);
  const isDummy = getQueryParam("isDummy", location);

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const [getDistricts, { loading, error, data }] = useLazyQuery(
    GET_DISTRICT_LIST,
    {
      client: entitesClient,
    }
  );

const [updateDistrict, { loading: loadingUp, error: errorUp, data: dataUp }] = useMutation(UPDATE_DISTRICT, {
    client: entitesClient, // Ensure you're using the correct GraphQL client
  });
  
  const handleUpdateDistrict = async () => {
    try {
      const response:any = await updateDistrict({
        variables: {
          input: {
            objectId: objectId,
            name: watch()?.name,
            State: watch()?.state,
            sbdStateId: getValueFromMetadata(metaData,"state_list", "state_id", watch()?.state,"sbd_state_id"),
            sbdDistrictId: +watch()?.sbdDistrictId,
            isDummy: watch()?.isDummy=="true"?true:false,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });
  
      if (response?.data?.updateDistrict?.success) {
        console.log("District updated successfully:", response?.data?.updateDistrict?.message);
        dispatch(snackbarRequest({ message: "Updated District successfully" }));
        history.replace(`/entities/district`);
      } else {
        console.log("District update failed:", response?.data?.updateDistrict?.message);
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));

      }
    } catch (err) {
      console.error("Error updating district:", err);
      dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));

    }
  };
  
useEffect(() => {
    if (data?.getDistrict) {
      const { getDistrict } = data;
      if (!isEmpty(getDistrict[0])) {
        setValue("name", getDistrict[0]?.name);
        setValue("state", getDistrict[0]?.State);
        setValue("sbdStateId", getDistrict[0]?.sbdStateId);
        setValue("sbdDistrictId", getDistrict[0]?.sbdDistrictId);
        setValue("isDummy", getDistrict[0]?.isDummy == true ? "true" : "false");
        setValue(
          "isArchived",
          getDistrict[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);

  useEffect(() => {
     getDistricts({
       variables: {
         filter: {
           objectId: objectId,
           isArchived: isArchived === "true" ? true : false,
           isDummy: isDummy === "true" ? true : false,
         },
       },
     });
   }, []);

  return (
    <div>
      <TypographyHeading>Update District</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={districtPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleUpdateDistrict}
             disabled={!isFieldsValidate(errors, districtMandatoryFields, getValues)}
             >
              Update District
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
