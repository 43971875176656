import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  batchInitialValues,
  batchMandatoryFields,
  batchProperties,
  batchValidationSchema,
  setCreateBatchMetaData,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import { CREATE_BATCH } from "utils/graphql/services/entities/mutations";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";

export const BatchCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    batchInitialValues,
    batchValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [createBatch, { loading, error, data }] = useMutation(CREATE_BATCH, {
    client: entitesClient, 
  });

  const handleCreateBatch = async () => {
   
    try {
      const response:any = await createBatch({
        variables: {
          input: {
            name: watch()?.name,
            platform: watch()?.platform,
            sbdBatchId: watch()?.sbdBatchId,
            examModelList: watch()?.examModelList,
            metadata: setCreateBatchMetaData(watch())
          },
        },
      });
  
      if(!isEmpty(response?.data?.createBatch)){
        dispatch(snackbarRequest({ message: "Created Batch successfully" }));
        history.replace(`/entities/batch`);
      }else{
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));

      }
    } catch (err:any) {
      dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
    }
  };
  
  return (
    <div>
      <TypographyHeading>Create Batch</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={batchProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleCreateBatch}
               disabled={!isFieldsValidate(errors, batchMandatoryFields, getValues)}
               >
              Create Batch
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
