import history from "utils/history";

export const PLATFORM_CONFIG_ROLES = {
  APP_UPDATE_VIEWER: "app_update_viewer",
  APP_UPDATE_MANAGER: "app_update_manager",
  FEATURE_CONFIG_VIEWER: "feature_config_viewer",
  FEATURE_CONFIG_CREATOR: "feature_config_creator",
  COURSE_CONFIG_VIEWER: "course_config_viewer",
  COURSE_CONFIG_CREATOR: "course_config_creator",
  PROGRAM_CONFIG_VIEWER: "program_config_viewer",
  PROGRAM_CONFIG_CREATOR: "program_config_creator",
  COURSE_CREATOR: "course_creator",
  PROGRAM_CREATOR: "program_creator",
};

export const ACADEMIC_TAGS_ROLES = {
  ACADEMIC_TAGS_VIEWER:"academic_tags_viewer",
  ACADEMIC_TAGS_MANAGER:"academic_tags_manager",
}

export const NCMS_ROLES = {
  CONTENT_ADMIN: "content_admin",
  PLATFORM_ADMIN: "platform_admin",
  ALL: "all",
  ACADEMIC_TAGS_ADMIN:"academic_tags_admin"
};

export const plateformConfigueUser = {
  FORCE_UPDATE_READ: [PLATFORM_CONFIG_ROLES.APP_UPDATE_VIEWER],
  FORCE_UPDATE_READ_WRITE: [PLATFORM_CONFIG_ROLES.APP_UPDATE_MANAGER],
  FEATURE_CONFIGUE_READ: [PLATFORM_CONFIG_ROLES.FEATURE_CONFIG_VIEWER],
  FEATURE_CONFIGUE_READ_WRITE: [PLATFORM_CONFIG_ROLES.FEATURE_CONFIG_CREATOR],
  STD_COURSE_LU_READ: [PLATFORM_CONFIG_ROLES.COURSE_CONFIG_VIEWER],
  STD_COURSE_LU_READ_WRITE: [PLATFORM_CONFIG_ROLES.COURSE_CONFIG_CREATOR],
  STD_PROGRAM_LU_READ: [PLATFORM_CONFIG_ROLES.PROGRAM_CONFIG_VIEWER],
  STD_PROGRAM_LU_READ_WRITE: [PLATFORM_CONFIG_ROLES.PROGRAM_CONFIG_CREATOR],
  CREATE_COURSE_READ_WRITE: [PLATFORM_CONFIG_ROLES.COURSE_CREATOR],
  CREATE_PROGRAM_READ_WRITE: [PLATFORM_CONFIG_ROLES.PROGRAM_CREATOR],
};

export const academicTagsUser = {
  ACADEMIC_TAGS_READ:[ACADEMIC_TAGS_ROLES.ACADEMIC_TAGS_VIEWER],
  ACADEMIC_TAGS_READ_WRITE:[ACADEMIC_TAGS_ROLES.ACADEMIC_TAGS_MANAGER]
}

export function checkFeatureAccess(userAccess, featureAccess) {
  return userAccess?.some((element) => featureAccess?.includes(element));
}

export const authenticateAndRedirect = (userAccess) => {
  if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.FORCE_UPDATE_READ,
      ...plateformConfigueUser?.FORCE_UPDATE_READ_WRITE,
    ])
  ) {
    history.replace("configue/force_update");
  } else if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.FEATURE_CONFIGUE_READ,
      ...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE,
    ])
  ) {
    history.replace("configue/feature_configue");
  } else if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.STD_COURSE_LU_READ,
      ...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE,
    ])
  ) {
    history.replace("configue/student_course_lookup");
  } else if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.STD_PROGRAM_LU_READ,
      ...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE,
    ])
  ) {
    history.replace("configue/student_program_lookup");
  } else if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.CREATE_COURSE_READ_WRITE,
    ])
  ) {
    history.replace("configue/create_course");
  } else if (
    checkFeatureAccess(userAccess, [
      ...plateformConfigueUser?.CREATE_PROGRAM_READ_WRITE,
    ])
  ) {
    history.replace("configue/create_program");
  }
};
