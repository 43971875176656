import { isEmpty } from "utils/helpers";

export function hasAtLeastOneValue(obj) {
  return Object.values(obj).some(
    (value) => value !== "" && value !== null && value !== undefined
  );
}

function isValidData(value) {
  if (value === null || value === undefined || value === '') {
    return false; // Invalid if null, undefined, or empty string
  }
  if (typeof value === 'boolean') {
    return true; // Booleans are always valid
  }
  if (value instanceof Date) {
    return !isNaN(value.getTime()); // Valid if it's a proper Date object
  }
  if (typeof value === 'string') {
    return value.trim().length > 0; // Valid if it's a non-empty string (ignoring spaces)
  }
  // For other types (e.g., numbers, objects), assume they are valid
  return true;
}

export function hasLengthGreaterThanZero(obj, exceptKey) {
  for (let key in obj) {
    //if (key !== exceptKey && obj[key]?.length > 0) {
    if (key !== exceptKey && isValidData(obj[key])) {
      return false;
    }
  }
  return true;
}

// export function hasLengthGreaterThanZeroArray(obj: Record<string, any>, 
//   exceptKeys: string[] = []) {
//   for (let key in obj) {
//     if(key == "isArchived"){
//       if(obj[key]=="true"){
//          return true
//       }else{
//         continue
//       }
//     }
//      else if (!exceptKeys?.includes(key) && isValidData(obj[key])) {
//       return false;
//     }
//   }
//   return true;
// }

export const getValueFromMetadata = (
  metaData,
  listValue,
  identifier,
  identifierValue,
  searchValue
) => {
  const maindata = metaData[`${listValue}`];
  const filteredData=maindata?.filter((obj)=>{
    if(obj[`${identifier}`]==identifierValue){
      return obj;
    }
  });
  return  filteredData[0]?.[`${searchValue}`]
};


export function areKeysFilled(obj) {
  return Object.values(obj).every(value => value !== "" && value !== null && value !== undefined);
}

export function getValuesByIds(idsArray, idArrayKey, idArrayName, metaKey, metadata) {
  // Extract the array of objects from metadata using the provided metaKey
  const itemList = metadata[metaKey];

  // If idsArray is a string, treat it as a single ID
  if (typeof idsArray === 'string' && !isEmpty(idsArray)) {
    const filteredItem = itemList.find(item => item[idArrayKey] === idsArray);
    return filteredItem ? filteredItem[idArrayName] : null;
  }

  // If idsArray is an array, proceed with filtering and mapping
  if (Array.isArray(idsArray) && !isEmpty(idsArray)) {
    return itemList
      .filter(item => idsArray.includes(item[idArrayKey]))
      .map(item => item[idArrayName]);
  }

  return "-";
}

export const META_FIELD_MAP = {
  CLASS_ID: "class_id",
  CLASS_NAME: "class_name",
  COURSE_ID: "course_id",
  COURSE_NAME: "course_name",
  REGISTRATION_STATUS_ID: "registration_status_id",
  REGISTRATION_STATUS_NAME: "registration_status_name",
  ACADEMIC_YEAR_ID: "year_id",
  ACADEMIC_YEAR_NAME: "year_name",
  STATE_ID: "state_id",
  STATE_NAME: "state_name",
  BOARD_ID: "board_id",
  BOARD_NAME: "board_name",
  BATCH_ID: "batch_id",
  BATCH_NAME: "batch_name",
  COLLEGE_ID: "college_id",
  COLLEGE_NAME: "college_name",
  SUB_BATCH_ID: "sub_batch_id",
  SUB_BATCH_NAME: "sub_batch_name",
  PROGRAM_ID: "program_id",
  PROGRAM_NAME: "program_name"
};

export const PC_METADATA_KEYS = {
  CLASS_LIST: "class_course_batch_data",
  COURSE_LIST: "course_list",
  REGISTRATION_STATUS_LIST: "reservation_status_list",
  ACADEMIC_YEAR_LIST: "academic_year_list",
  STATE_LIST: "state_list",
  BOARD_LIST: "board_list",
  BATCH_LIST: "pc_batch_list",
  COLLEGE_LIST: "pc_college_list",
  SUB_BATCH_LIST: "pc_sub_batch_list",
  PROGRAM_LIST: "program_class_data",
};


export function hasValidValue(obj, key,key2="") {
  if (obj[key] === "true") {
    return true;
  }
  if (obj[key2] === "true") {
    return true;
  }

  return Object.entries(obj)
    .filter(([k]) => k !== key && k !== key2) // Exclude the provided key
    .some(([_, value]) => value !== "" && value !== null && value !== undefined);
}


export function hasValidValueIgnore(obj, key, ignoreKey,key1) {
  if (obj[key] === "true" || obj[key1] === "true") {
    return true;
  }

  return Object.entries(obj)
    .filter(([k]) => k !== key && k !== ignoreKey && k !== key1) // Exclude both keys
    .some(([_, value]) => value !== "" && value !== null && value !== undefined);
}