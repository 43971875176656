import { ACADEMIC_TAGS_ROLES, academicTagsUser } from "utils/role-restriction/helper";
import { BatchEntitie } from "./routes/batch";
import { BatchCreate } from "./routes/batch/create";
import { BatchUpdate } from "./routes/batch/update";
import { BranchEntitie } from "./routes/branch";
import { BranchCreate } from "./routes/branch/create";
import { BranchUpdate } from "./routes/branch/update";
import { StateEntitie } from "./routes/state";
import { StateCreate } from "./routes/state/create";
import { StateUpdate } from "./routes/state/update";
import { ClassEntitie } from "./routes/class";
import { ClassCreate } from "./routes/class/create";
import { ClassUpdate } from "./routes/class/update";
import { DistrictEntitie } from "./routes/district";
import { DistrictCreate } from "./routes/district/create";
import { DistrictUpdate } from "./routes/district/update";
import { ActualSbdBatchEntitie } from "./routes/actual-sbd-batch";
import { ActualSbdBatchCreate } from "./routes/actual-sbd-batch/create";
import { ActualSbdBatchUpdate } from "./routes/actual-sbd-batch/update";
import { ActualSbdProgramEntitie } from "./routes/actual-sbd-program";
import { ActualSbdProgramCreate } from "./routes/actual-sbd-program/create";
import { ActualSbdProgramUpdate } from "./routes/actual-sbd-program/update";
import { ClassBatchMapEntitie } from "./routes/class-batch-mapping";
import { ClassBatchMapCreate } from "./routes/class-batch-mapping/create";
import { ClassBatchMapUpdate } from "./routes/class-batch-mapping/update";
import { YearEntitie } from "./routes/year";
import { AcedamicYearCreate } from "./routes/year/create";
import { AcedamicYearUpdate } from "./routes/year/update";

export const EntitiesRoutes = [
  {
    exact: true,
    path: "/batch",
    component: BatchEntitie,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/batch/create",
    component:BatchCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/batch/edit",
    component:BatchUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/branch",
    component:BranchEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/branch/create",
    component:BranchCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/branch/edit",
    component:BranchUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/state",
    component:StateEntitie ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/state/create",
    component:StateCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/state/edit",
    component:StateUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class",
    component:ClassEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class/create",
    component:ClassCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class/edit",
    component:ClassUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/district",
    component:DistrictEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/district/create",
    component:DistrictCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/district/edit",
    component:DistrictUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_batch",
    component:ActualSbdBatchEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_batch/create",
    component:ActualSbdBatchCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_batch/edit",
    component:ActualSbdBatchUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_program",
    component:ActualSbdProgramEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_program/create",
    component:ActualSbdProgramCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/actual_sbd_program/edit",
    component:ActualSbdProgramUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class_batch_map",
    component:ClassBatchMapEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class_batch_map/create",
    component:ClassBatchMapCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/class_batch_map/edit",
    component:ClassBatchMapUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/year",
    component:YearEntitie ,
    user: [
        ...academicTagsUser.ACADEMIC_TAGS_READ,
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/year/create",
    component:AcedamicYearCreate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
  {
    exact: true,
    //path: "/year/edit/:objectId/:isArchived",
    path: "/year/edit",
    component:AcedamicYearUpdate ,
    user: [
      ...academicTagsUser.ACADEMIC_TAGS_READ_WRITE,
    ],
  },
];
