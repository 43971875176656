import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  classCreateInitialValues,
  classCreateMandatoryFields,
  classCreateProperties,
  classCreateValidationSchema,
  handleDisableCreate,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import { CREATE_CLASS } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getClassListData } from "utils/metadata-split/metaDataApiFetch";

export const ClassCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    classCreateInitialValues,
    classCreateValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const [createClass, { loading, error, data }] = useMutation(CREATE_CLASS, {
    client: entitesClient, // Ensure you use the correct GraphQL client instance
  });

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateClassCourseListDate = async () => {
   try {
    const call1:any= await  getClassListData(updateMetaData)
     updateMetaData(call1[0], call1[1]);
   } catch (error) {
     console.log(error);
   }
 };
  
  const handleCreateClass = async () => {
    try {
      const response:any = await createClass({
        variables: {
          input: {
            name: watch()?.name,
            synonyms: watch()?.synonyms.length>0?watch()?.synonyms.split(","):watch()?.name,
            sbdClassId: watch()?.sbdClassId,
          },
        },
      });
      if(!isEmpty(response?.data?.createClass)){
        dispatch(snackbarRequest({ message: "Created Class successfully" }));
        await handleUpdateClassCourseListDate()
        history.replace(`/entities/class`);
      }else{
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));
      }
    } catch (err:any) {
      dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
    }
  };

  return (
    <div style={{height:"80px"}}>
      <TypographyHeading>Create class</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={classCreateProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleCreateClass}
             // disabled={!isFieldsValidate(errors, classCreateMandatoryFields, getValues)}
              disabled={handleDisableCreate(watch())}
              >
              Create class
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

