import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  actualSbdProgramInitialValues,
  actualSbdProgramMandatoryFields,
  actualSbdProgramProperties,
  actualSbdProgramValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import { CREATE_ACTUAL_SBD_PROGRAM } from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { areKeysFilled } from "pages/entities/helper";

export const ActualSbdProgramCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    actualSbdProgramInitialValues,
    actualSbdProgramValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [createActualSbdProgram, { loading, error, data }] = useMutation(CREATE_ACTUAL_SBD_PROGRAM, {
    client: entitesClient, // Ensure you're using the correct GraphQL client instance
  });
  
  const handleCreateActualSbdProgram = async () => {
    try {
      const response = await createActualSbdProgram({
        variables: {
          input: {
            sbdProgramId: watch()?.sbdProgramId,
            sbdProgramName: watch()?.sbdProgramName,
          },
        },
      });
  
      if (!isEmpty(response?.data?.createActualSbdProgram)) {
        console.log("SbdProgram created:", response?.data?.createActualSbdProgram);
        dispatch(snackbarRequest({ message: "Created actualSbdProgram successfully" }));
        history.replace(`/entities/actual_sbd_program`);
      } else {
        console.log("SbdProgram creation failed");
        dispatch(snackbarRequest({ message: "Failed to Create actualSbdProgram",type: "error"  }));
      }
    } catch (err:any) {
      console.error("Error creating SbdProgram:", err);
      dispatch(snackbarRequest({ message: `${err?.message}`,type: "error"  }));
    }
  };

  return (
    <div>
      <TypographyHeading>Create Actual Sbd Program</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={actualSbdProgramProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleCreateActualSbdProgram}
             //  disabled={!isFieldsValidate(errors, actualSbdProgramMandatoryFields, getValues)}
             disabled={enableButton(watch())}
               >
              Create Actual Sbd Program
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

const enableButton=(obj)=>{
  if(isEmpty(obj?.sbdProgramId) || isEmpty(obj?.sbdProgramName)){
    return true
  }else{
    return false
  }
}
