import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { acedamicYearInitialValues, acedamicYearMandatoryFields, acedamicYearProperties, acedamicYearValidationSchema } from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import { CREATE_YEAR } from "utils/graphql/services/entities/mutations";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getAcademicYearListData } from "utils/metadata-split/metaDataApiFetch";

export const AcedamicYearCreate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    acedamicYearInitialValues,
    acedamicYearValidationSchema,
    ""
  );
  const [createYear, { loading, error, data }] = useMutation(CREATE_YEAR, {
    client: entitesClient,
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  
  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };
  
  const handleUpdateYearListDate = async () => {
   try {
    const call1:any= await  getAcademicYearListData(updateMetaData)
    updateMetaData(call1[0], call1[1]);
   } catch (error) {
     console.log(error);
   }
  };

  const handleCreateYear = async () => {
    let obj={};
    if(watch()?.downloadstatsViewStatus=="true"){
      obj["downloadstatsViewStatus"]=true;
    }
    try {
      const response:any = await createYear({
        variables: {
          input: {
            name: watch()?.name,
            academicYear: watch()?.academicYear,
           // yearId: 100 ,
            isUpcoming: watch()?.isUpcoming=="false"?false:true,
            sbdYearId:watch()?.sbdYearId ,
            metadata: {
            ...obj
            },
          },
        },
      });
      if (!isEmpty(response?.data?.createYear)) {
        console.log("SbdBatch created:", response?.data?.createYear);
        dispatch(snackbarRequest({ message: "Created Acedamic Year successfully" }));
        await handleUpdateYearListDate();
        history.replace(`/entities/year`);
      } else {
        dispatch(snackbarRequest({ message: `${response?.message}`,type: "error"  }));
      }
    } catch (err:any) {
      dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));
    }
  };

  return (
    <div>
         <TypographyHeading>Create Acedamic Year</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2,pt:2, }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={acedamicYearProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              sx={{
                width: "200px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleCreateYear}
              disabled={!isFieldsValidate(errors, acedamicYearMandatoryFields, getValues)}
              loading={loading}
            >
              Create Acedamic Year
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
