import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useHistory } from "react-router-dom";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createCourseInitialValues,
  createCourseValidationSchema,
  createCourseProperties,
  createCourseMandatoryFields,
} from "./helper";
import configueService from "pages/configue/service";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "utils/helpers";
import { getCourseListData } from "utils/metadata-split/metaDataApiFetch";

export const UpdateCourse = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createCourseInitialValues,
    createCourseValidationSchema,
    ""
  );
  const { data, error, loading, updateCourse } = useAppSelector(
    (state) => state.createCourseSlice
  );
  const [btnLoading,setBtnLoading]=useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLoading,data:metaData }: any = useAppSelector((state) => state.metadataReducer);

  const updateMetaData:any=async(listData,metaKey)=>{
     let metadataList = {...metaData};
     metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList))
   }

   const handleUpdateCourseList = async () => {
    try {
      const call1: any = await getCourseListData(updateMetaData);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCourse = async () => {
    setBtnLoading(true);
    const displayNamePl = watch()?.display_name?.trim();
    const namePl = watch()?.name?.trim();
    
    if (isEmpty(displayNamePl) || isEmpty(namePl)) {
      if (isEmpty(displayNamePl) && isEmpty(namePl)) {
        dispatch(snackbarRequest({ message: "Please check/fill both name and display_name", type: "error" }));
      } else if (isEmpty(displayNamePl)) {
        dispatch(snackbarRequest({ message: "Please check/fill display_name", type: "error" }));
      } else {
        dispatch(snackbarRequest({ message: "Please check/fill name", type: "error" }));
      }
      setBtnLoading(false);
      return;
    }

    const reqBody = {
      class_id: watch()?.class_id,
      display_name: displayNamePl,
      name: namePl,
      course_id: updateCourse?.course_id,
    };

    const res: any = await configueService.updateCourseProperties(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "updated successfully" }));
      await  handleUpdateCourseList();
      setBtnLoading(false);
      history.replace(`/configue/create_course`);
    } else {
      setBtnLoading(false);
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };
  useEffect(() => {
    if (isEmpty(updateCourse)) {
      history.replace(`/configue/create_course`);
    } else {
      setValue("class_id", updateCourse?.class_id);
      setValue("display_name", updateCourse?.display_name);
      setValue("name", updateCourse?.course_name);
    }
  }, [updateCourse]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Update Course
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createCourseProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: -7 }}>
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            color="primary"
            onClick={handleUpdateCourse}
            loading={btnLoading}
            style={{
              width: "100px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            disabled={
              !isFieldsValidate(errors, createCourseMandatoryFields, getValues)
            }
          >
            update
          </LoadingButton>
        </ContentRight>
      </Paper>
    </div>
  );
};
