import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  actualSbdProgramInitialValues,
  actualSbdProgramMandatoryFields,
  actualSbdProgramProperties,
  actualSbdProgramPropertiesUpdate,
  actualSbdProgramValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_ACTUAL_SBD_PROGRAM,
  UPDATE_ACTUAL_SBD_PROGRAM,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import { GET_ACTUALSBDPROGRAM_LIST } from "utils/graphql/services/entities/queries";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { areKeysFilled } from "pages/entities/helper";

export const ActualSbdProgramUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    actualSbdProgramInitialValues,
    actualSbdProgramValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);

  const [getActualSbdProgram, { loading, error, data }] = useLazyQuery(
    GET_ACTUALSBDPROGRAM_LIST,
    {
      client: entitesClient,
    }
  );

  const [
    updateActualSbdProgram,
    { loading: loadingUp, error: errorUp, data: dataUp },
  ] = useMutation(UPDATE_ACTUAL_SBD_PROGRAM, {
    client: entitesClient,
  });

  const handleUpdateActualSbdProgram = async () => {
    try {
      const response: any = await updateActualSbdProgram({
        variables: {
          input: {
            id: +objectId,
            sbdProgramId: watch()?.sbdProgramId,
            sbdProgramName: watch()?.sbdProgramName,
            isArchived: watch()?.isArchived == "true" ? true : false,
          },
        },
      });

      if (response?.data?.updateActualSbdProgram?.success) {
        console.log(
          "Program updated successfully:",
          response?.data?.updateActualSbdProgram?.message
        );
        dispatch(
          snackbarRequest({ message: "Updated actualSbdProgram successfully" })
        );
        history.replace(`/entities/actual_sbd_program`);
      } else {
        console.log(
          "Program update failed:",
          response?.data?.updateActualSbdProgram?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating program:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    if (data?.getActualSbdProgram) {
      const { getActualSbdProgram } = data;
      if (!isEmpty(getActualSbdProgram[0])) {
        setValue("sbdProgramId", getActualSbdProgram[0]?.sbdProgramId);
        setValue("sbdProgramName", getActualSbdProgram[0]?.sbdProgramName);
        setValue(
          "isArchived",
          getActualSbdProgram[0]?.isArchived == true ? "true" : "false"
        );
      }
    }
  }, [data]);
  
  useEffect(() => {
    getActualSbdProgram({
      variables: { filter: { 
        id: +objectId,
        isArchived: isArchived === "true" ? true : false,
       } },
    });
  }, []);

  return (
    <div>
      <TypographyHeading>Update Actual Sbd Program</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={actualSbdProgramPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
               onClick={handleUpdateActualSbdProgram}
              // disabled={!isFieldsValidate(errors, actualSbdProgramMandatoryFields, getValues)}
              disabled={enableButton(watch())}
            >
              Update Actual Sbd Program
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};

const enableButton=(obj)=>{
  if(isEmpty(obj?.sbdProgramId) || isEmpty(obj?.sbdProgramName)){
    return true
  }else{
    return false
  }
}