import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";
export type branchFilter = {
  state_list: string;
  branch_list: string;
  start_date: string;
  end_date: string;
  isArchived: string;
  isDummy:string;
};

export const branchFilterInitialValues: branchFilter = {
  state_list: "",
  branch_list: "",
  start_date: "",
  end_date: "",
  isArchived: "false",
  isDummy:"false"
};

export const branchFilterMandatoryFields = [
  { id: "state_list" },
  { id: "branch_list" },
  { id: "start_date" },
  { id: "end_date" },
];

export const branchFilterValidationSchema = Yup.object().shape({

});

export const branchFilterProperties =(watch)=>{
  return ([
    {
      id: "state_list",
      type: "single-select-dependency",
      label: "State",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.5 },
      options: [],
      list: "state_list",
      keyValue: { label: "state_name", value: "state_id" },
      dependentFields: ["branch_list"],
      dependentFlds: {
        state_list: "state_list",
      },
    },
    {
      id: "branch_list",
      type: "single-select-dependency",
      label: "Branch",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      list: "branch_list",
      isDependent: true,
      required: true,
      fieldKeyLists: {},
      hasApiCall: true,
      endpoint: endpointsMd.GET_COLLEGE_LIST,
      requestList: async (payload) => {
        return { state_id:watch?.state_list };
      },
      changeValueId: "state_list",
      keyValue: { label: "college_name", value: "college_name"  },
      dependentFields: [""],
      dependentFlds: {},
      dependencyLabel: "Please select state",
    },
    {
      id: "start_date",
      type: "date-picker",
      label: "Created From",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: false,
    },
    {
      id: "end_date",
      type: "date-picker",
      label: "Created To",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: false,
    },
    {
      id: "isDummy",
      type: "normal-select",
      label: "isDummy",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      hideNone: true,
    }, 
    {
      id: "isArchived",
      type: "normal-select",
      label: "IsArchived",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      hideNone: true,
    }, 
  ]

  )
} 

export type branchFilterSearch = {
  branchName: string;
};

export const branchFilterInitialValuesSearch: branchFilterSearch = {
  branchName: "",
};
export const branchFilterValidationSchemaSearch = Yup.object().shape({

});


export const branchFilterPropertiesSearch=[
  {
    id: "branchName",
    type: "text",
    label: `Branch Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: false,
    placeholder:"Search By Branch Name"
  },
]