import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";
export type batchFilter = {
  class_list: string;
  batch_list: string;
  start_date: string;
  end_date: string;
  isArchived:string;
  isDummy:string;
};
// objectId String
// Add Fragment
// name String
// Add Fragment
// createdFromDate String
// Add Fragment
// createdToDate String
export const batchFilterInitialValues: batchFilter = {
  class_list: "",
  batch_list: "",
  start_date: "",
  end_date: "",
  isArchived:"false",
  isDummy:"false"
};

export const batchFilterMandatoryFields = [
  { id: "class_list" },
  { id: "batch_list" },
  { id: "start_date" },
  { id: "end_date" },
];

export const batchFilterValidationSchema = Yup.object().shape({});

export const batchFilterProperties = [
  {
    id: "batch_list",
    type: "normal-select",
    label: "Batch",
    list: "batch_list",
    className: "select-styles",
    required: false,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    onlyApiCall: true,
      requestList: async (payload) => {
        return {};
      },
    endpoint:endpointsMd.GET_BATCH_LIST,
    keyValue: { label: "batch_name", value: "batch_name" },
    //keyValue: { label: "name", value: "object_id" },
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isDummy",
    type: "normal-select",
    label: "isDummy",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];
