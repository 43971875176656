import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useAppDispatch } from "hooks/redux";
import Alert from '@mui/material/Alert'

type snackbarProps = {
  open?: boolean;
  type?: "success" | "warning" | "error" | "info";
  message?: string;
  handleClose?: any;
  severity?: any;
};

export default function SnackBar(props: snackbarProps) {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(snackbarRequest(""));
  };

  return (
    <div>
      <Snackbar open={props.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={props.type ? props.type : 'success'} sx={{ width: '90%' }} variant='filled'>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
