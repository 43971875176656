import * as Yup from "yup";

export const table = {
  primaryData: [
    {
      title: "AcademicYear",
      value: "academicYear",
    },
    {
      title: "Name",
      value: "name",
    },
    {
      title: "sbdYearId",
      value: "sbdYearId",
    },
    {
      title: "isUpcoming",
      value: "isUpcoming",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    },
    {
      title: "Actions",
      width: "5%",
      sendIsArchived:true
    },
  ],
  secondaryData: [
  ],
};

export const tableRead = {
  primaryData: [
    {
      title: "AcademicYear",
      value: "academicYear",
    },
    {
      title: "Name",
      value: "name",
    },
    {
      title: "sbdYearId",
      value: "sbdYearId",
    },
    {
      title: "isUpcoming",
      value: "isUpcoming",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    },
  ],
  secondaryData: [
  ],
};

export type acedamicYear = {
  name: string;
  academicYear: string;
 // yearId: Number | string;
  isUpcoming: Boolean | string;
  sbdYearId: string;
  downloadstatsViewStatus: string;
  // isDummy: Boolean | string;
};

export const acedamicYearInitialValues: acedamicYear = {
  name: "",
  academicYear: "",
 // yearId: "",
  isUpcoming: "false",
  sbdYearId: "",
  downloadstatsViewStatus: "false",
  // isDummy: "false",
};

export const acedamicYearMandatoryFields = [
  { id: "name" },
  { id: "academicYear" },
 // { id: "yearId" },
  { id: "isUpcoming" },
  { id: "sbdYearId" },
  { id: "downloadstatsViewStatus" },
];

export const acedamicYearValidationSchema = Yup.object().shape({
  academicYear: Yup.string()
    .matches(/^\d{4}-\d{4}$/, "Academic year must be in the format YYYY-YYYY")
    .required("Academic year is required"),
  name: Yup.string()
    .matches(/^\d{4}-\d{4}$/, "Name must be in the format YYYY-YYYY")
    .required("Academic year is required"),
  // yearId: Yup.number()
  //   .typeError("Year ID must be a number")
  //   .required("Year ID is required"),
  sbdYearId: Yup.string().required("SBD Year ID is required"),
});

export const acedamicYearProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "academicYear",
    type: "text",
    label: `Academic Year`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  // {
  //   id: "yearId",
  //   type: "number",
  //   label: "Year Id *",
  //   className: "number-styles",
  //   breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
  // },
  {
    id: "sbdYearId",
    type: "text",
    label: `Sbd Year Id`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "isUpcoming",
    type: "normal-select",
    label: "is Upcoming",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "downloadstatsViewStatus",
    type: "normal-select",
    label: "downloadstatsViewStatus",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
];

export const updateAcedamicYearProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "academicYear",
    type: "text",
    label: `Academic Year`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  // {
  //   id: "yearId",
  //   type: "number",
  //   label: "Year Id",
  //   className: "number-styles",
  //   breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
  //   required: true,
  // },
  {
    id: "sbdYearId",
    type: "text",
    label: `Sbd Year Id`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "isUpcoming",
    type: "normal-select",
    label: "is Upcoming",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "downloadstatsViewStatus",
    type: "normal-select",
    label: "downloadstatsViewStatus",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];
