import { gql } from "@apollo/client";

export const GET_STATE_LIST = gql`
  query getState($filter: StateFilter!) {
    getState(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      sbdStateId
      isDummy
      isArchived
    }
  }
`;

export const GET_DISTRICT_LIST = gql`
  query getDistrict($filter: DistrictFilter!) {
    getDistrict(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      State
      sbdStateId
      sbdDistrictId
      isDummy
      isArchived
    }
  }
`;

export const GET_BRANCH_LIST = gql`
  query getBranch($filter: BranchFilter!) {
    getBranch(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      District
      State
      entityId
      sbdCollegeId
      sbdDistrictId
      sbdStateId
      isArchived
      isDummy
    }
  }
`;
export const GET_BATCH_LIST = gql`
  query getBatch($filter: BatchFilter!) {
    getBatch(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      streamId
      Stream
      LearningPackageId
      PracticePackageId
      LearningPackage
      PracticePackage
      secondaryname
      tertiaryname
      fourthname
      fifthname
      Course
      examModelList
      platform
      isDummy
      sbdBatchId
      actualSbdBatchId
      actualSbdProgramId
      metadataJson
      metadata {
        isDigital
        isPracticeDisabled
        isDoubtSolvingDisabled
        isDigitalNarayanaEnabled
        downloadstatsViewStatus
        isJeeBatch
        isLearnDisabled
        asPerCourseColumn
        sectionNameToBeOverridden
        isRevisenowNotificationDisabled
        platform
        isEchampsOrEkidzClass
        isBelongsToCollege
        isPrePrimaryClass
        isTwelthClassPass
        isBelongsToHighSchool
      }
      isArchived
    }
  }
`;

export const GET_CLASS_LIST = gql`
  query getClass($filter: ClassFilter!) {
    getClass(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      synonyms
      isDummy
      sbdClassId
      metadataJson
      metadata {
        isDigital
        isPracticeDisabled
        isDoubtSolvingDisabled
        isDigitalNarayanaEnabled
        downloadstatsViewStatus
        isJeeBatch
        isLearnDisabled
        asPerCourseColumn
        sectionNameToBeOverridden
        isRevisenowNotificationDisabled
        platform
        isEchampsOrEkidzClass
        isBelongsToCollege
        isPrePrimaryClass
        isTwelthClassPass
        isBelongsToHighSchool
      }
      isArchived
    }
  }
`;

export const GET_YEAR_LIST = gql`
  query getYear($filter: YearFilter!) {
    getYear(filter: $filter) {
      objectId
      createdAt
      updatedAt
      name
      academicYear
      yearId
      isUpcoming
      sbdYearId
      metadataJson
      metadata {
        isDigital
        isPracticeDisabled
        isDoubtSolvingDisabled
        isDigitalNarayanaEnabled
        downloadstatsViewStatus
        isJeeBatch
        isLearnDisabled
        asPerCourseColumn
        sectionNameToBeOverridden
        isRevisenowNotificationDisabled
        platform
        isEchampsOrEkidzClass
        isBelongsToCollege
        isPrePrimaryClass
        isTwelthClassPass
        isBelongsToHighSchool
      }
      isArchived
    }
  }
`;

export const GET_ACTUALSBDBATCH_LIST = gql`
  query getActualSbdBatch($filter: ActualSbdBatchFilter!) {
    getActualSbdBatch(filter: $filter) {
      id
      createdAt
      updatedAt
      sbdBatchId
      sbdBatchName
      isArchived
    }
  }
`;

export const GET_ACTUALSBDPROGRAM_LIST = gql`
  query getActualSbdProgram($filter: ActualSbdProgramFilter!) {
    getActualSbdProgram(filter: $filter) {
      id
      createdAt
      updatedAt
      sbdProgramId
      sbdProgramName
      isArchived
    }
  }
`;

export const GET_CLASSBATCHMAPPING_LIST = gql`
  query getClassBatchMapping($filter: ClassBatchMappingFilter!) {
    getClassBatchMapping(filter: $filter) {
      objectId
      createdAt
      updatedAt
      Batch
      Class
      isArchived
    }
  }
`;
