import * as Yup from "yup";

export const table = {
  primaryData: [
    {
      title: "Batch",
      value: "name",
      type: "configue",
    },
    {
      title: "Platform",
      value: "platform",
    },
    {
      title: "examModelList",
      value: "examModelList",
      type: "configue",
    },
    {
      title: "SbdBatchId",
      value: "sbdBatchId",
    },
    {
      title: "IsDummy",
      value: "isDummy",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    },
    {
      title: "Actions",
      width: "5%",
      sendIsArchived:true
    },
  ],
  secondaryData: [],
};
export const tableRead = {
  primaryData: [
    {
      title: "Batch",
      value: "name",
      type: "configue",
    },
    {
      title: "Platform",
      value: "platform",
    },
    {
      title: "examModelList",
      value: "examModelList",
      type: "configue",
    },
    {
      title: "SbdBatchId",
      value: "sbdBatchId",
    },
    {
      title: "IsDummy",
      value: "isDummy",
    },
    {
      title: "IsArchived",
      value: "isArchived",
    },
    {
      title: "CreatedAt",
      value: "createdAt",
      type: "configue",
    },
    {
      title: "updatedAt",
      value: "updatedAt",
    }
  ],
  secondaryData: [],
};

export type batch = {
  name: string;
  platform: string;
  sbdBatchId: Number | string;
  // Stream: string;
  examModelList: string[];
  isRevisenowNotificationDisabled: Boolean | string;
  isDigital: Boolean | string;
  isPracticeDisabled: Boolean | string;
  isDoubtSolvingDisabled: Boolean | string;
  isDigitalNarayanaEnabled: Boolean | string;
  isDummy: Boolean | string;
};

export const batchInitialValues: batch = {
  name: "",
  platform: "",
  sbdBatchId: "",
  // Stream: "",
  examModelList: [],
  isRevisenowNotificationDisabled: "false",
  isDigital: "false",
  isPracticeDisabled: "false",
  isDoubtSolvingDisabled: "false",
  isDigitalNarayanaEnabled: "false",
  isDummy: "false",
};

export const batchMandatoryFields = [
  { id: "name" },
  { id: "platform" },
  { id: "sbdBatchId" },
  // { id: "Stream" },
  { id: "examModelList" },
  // { id: "isRevisenowNotificationDisabled" },
  // { id: "isDigital" },
  // { id: "isPracticeDisabled" },
  // { id: "isDoubtSolvingDisabled" },
  // { id: "isDigitalNarayanaEnabled" },
];

export const batchValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sbdBatchId: Yup.string().required("SBD Batch ID is required"),
  platform: Yup.string().required("Platform is required"),
  examModelList: Yup.array().required().min(1, "ExamModelList is required"),
});

export const batchProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "platform",
    //  type: "multi-select-dependency",
    type: "normal-select",
    label: "Platform *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    // isDependent: true,
    options: [],
    list: "platform_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "label", value: "value" },
    fieldKeyLists: {},
  },
  {
    id: "sbdBatchId",
    type: "text",
    label: `Sbd Batch Id`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  // {
  //   id: "stream",
  //   type: "text",
  //   label: `Stream`,
  //   className: "name-styles",
  //   breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
  //   required: true,
  // },
  {
    id: "examModelList",
    type: "multi-select",
    label: "Select Exam Model",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "exam_model_list",
    keyValue: { label: "exam_model_name", value: "exam_model_id" },
  },
  {
    id: "isRevisenowNotificationDisabled",
    type: "normal-select",
    label: "isRevisenowNotificationDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDigital",
    type: "normal-select",
    label: "isDigital",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isPracticeDisabled",
    type: "normal-select",
    label: "isPracticeDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDoubtSolvingDisabled",
    type: "normal-select",
    label: "isDoubtSolvingDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDigitalNarayanaEnabled",
    type: "normal-select",
    label: "isDigitalNarayanaEnabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
];
// isRevisenowNotificationDisabled:"false",
// isDigital:"false",
// isPracticeDisabled:"false",
// isDoubtSolvingDisabled:"false",
// isDigitalNarayanaEnabled:"false",

export const setCreateBatchMetaData = (value) => {
  let obj = {};
  if (value?.isRevisenowNotificationDisabled == "true") {
    obj["isRevisenowNotificationDisabled"] = true;
  }
  if (value?.isDigital == "true") {
    obj["isDigital"] = true;
  }
  if (value?.isPracticeDisabled == "true") {
    obj["isPracticeDisabled"] = true;
  }
  if (value?.isDoubtSolvingDisabled == "true") {
    obj["isDoubtSolvingDisabled"] = true;
  }
  if (value?.isDigitalNarayanaEnabled == "true") {
    obj["isDigitalNarayanaEnabled"] = true;
  }
  return obj;
};

export const batchPropertiesUpdate = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  {
    id: "platform",
    //  type: "multi-select-dependency",
    type: "normal-select",
    label: "Platform *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    // isDependent: true,
    options: [],
    list: "platform_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "label", value: "value" },
    fieldKeyLists: {},
  },
  {
    id: "sbdBatchId",
    type: "text",
    label: `Sbd Batch Id`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
    required: true,
  },
  // {
  //   id: "stream",
  //   type: "text",
  //   label: `Stream`,
  //   className: "name-styles",
  //   breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
  //   required: true,
  // },
  {
    id: "examModelList",
    type: "multi-select",
    label: "Select Exam Model",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "exam_model_list",
    keyValue: { label: "exam_model_name", value: "exam_model_id" },
  },
  {
    id: "isRevisenowNotificationDisabled",
    type: "normal-select",
    label: "isRevisenowNotificationDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDigital",
    type: "normal-select",
    label: "isDigital",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isPracticeDisabled",
    type: "normal-select",
    label: "isPracticeDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDoubtSolvingDisabled",
    type: "normal-select",
    label: "isDoubtSolvingDisabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDigitalNarayanaEnabled",
    type: "normal-select",
    label: "isDigitalNarayanaEnabled",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isDummy",
    type: "normal-select",
    label: "isDummy",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];
