import React from 'react'
//import PdfViewer from '@bit/nspiracbd.nspira-modules.pdf-viewer';
import PdfViewer from "./PdfViewer"
const PdfViewerComp = ({ match, location }) => {
  // 
  return (
    <div  >
       <PdfViewer
        match={match}
        location={location}
        hasDownload={true}
      />
    </div>
  )
}

export default PdfViewerComp
