import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { BatchEntitie } from "./routes/batch";
import { BranchEntitie } from "./routes/branch";
import { StateEntitie } from "./routes/state";
import { ClassEntitie } from "./routes/class";
import { DistrictEntitie } from "./routes/district";
import { ActualSbdBatchEntitie } from "./routes/actual-sbd-batch";
import { ActualSbdProgramEntitie } from "./routes/actual-sbd-program";
import { ClassBatchMapEntitie } from "./routes/class-batch-mapping";
import { YearEntitie } from "./routes/year";
import { AcedamicYearCreate } from "./routes/year/create";
import { BatchCreate } from "./routes/batch/create";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getExamModelListData } from "utils/metadata-split/metaDataApiFetch";
import { BranchCreate } from "./routes/branch/create";
import { ClassCreate } from "./routes/class/create";
import { StateCreate } from "./routes/state/create";
import { DistrictCreate } from "./routes/district/create";
import { ActualSbdBatchCreate } from "./routes/actual-sbd-batch/create";
import { ActualSbdProgramCreate } from "./routes/actual-sbd-program/create";
import { ClassBatchMapCreate } from "./routes/class-batch-mapping/create";
import { AcedamicYearUpdate } from "./routes/year/update";
import { BatchUpdate } from "./routes/batch/update";
import { BranchUpdate } from "./routes/branch/update";
import { ClassUpdate } from "./routes/class/update";
import { StateUpdate } from "./routes/state/update";
import { DistrictUpdate } from "./routes/district/update";
import { ActualSbdBatchUpdate } from "./routes/actual-sbd-batch/update";
import { ActualSbdProgramUpdate } from "./routes/actual-sbd-program/update";
import { ClassBatchMapUpdate } from "./routes/class-batch-mapping/update";
import { EntitiesRoutes } from "./routes";
import { checkFeatureAccess } from "utils/role-restriction/helper";
export interface IMatch {
  url: string;
}

export function EntitiesPage({ match }: { match: IMatch }) {
  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const dispatch = useAppDispatch();
  const [showLoading, setShowIsLoading] = useState(true);

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
    setShowIsLoading(false);
  };

  useEffect(() => {}, [metaData]);

  useEffect(() => {
    (async () => {
      try {
        const call1: any = await getExamModelListData(updateMetaData);
        updateMetaData(call1[0], call1[1]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (showLoading) {
    return <p>loading....</p>;
  }
  return (
    <>
      <Switch>
      {EntitiesRoutes.map((route, i) => {
          return (
            checkFeatureAccess(userRole, route?.user) && (
              <Route
                key={i}
                exact
                path={`${match.url}${route.path}`}
                component={route.component}
              />
            )
          );
        })}
        {/* <Route exact path={`${match.url}/batch`} component={BatchEntitie} />
        <Route
          exact
          path={`${match.url}/batch/create`}
          component={BatchCreate}
        />
        <Route
          exact
          path={`${match.url}/batch/edit/:objectId`}
          component={BatchUpdate}
        />
        <Route exact path={`${match.url}/branch`} component={BranchEntitie} />
        <Route
          exact
          path={`${match.url}/branch/create`}
          component={BranchCreate}
        />
        <Route
          exact
          path={`${match.url}/branch/edit/:objectId`}
          component={BranchUpdate}
        />
        <Route exact path={`${match.url}/state`} component={StateEntitie} />
        <Route
          exact
          path={`${match.url}/state/create`}
          component={StateCreate}
        />
        <Route
          exact
          path={`${match.url}/state/edit/:objectId`}
          component={StateUpdate}
        />
        <Route exact path={`${match.url}/class`} component={ClassEntitie} />
        <Route
          exact
          path={`${match.url}/class/create`}
          component={ClassCreate}
        />
        <Route
          exact
          path={`${match.url}/class/edit/:objectId`}
          component={ClassUpdate}
        />
        <Route
          exact
          path={`${match.url}/district`}
          component={DistrictEntitie}
        />
        <Route
          exact
          path={`${match.url}/district/create`}
          component={DistrictCreate}
        />
        <Route
          exact
          path={`${match.url}/district/edit/:objectId`}
          component={DistrictUpdate}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_batch`}
          component={ActualSbdBatchEntitie}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_batch/create`}
          component={ActualSbdBatchCreate}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_batch/edit/:id`}
          component={ActualSbdBatchUpdate}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_program`}
          component={ActualSbdProgramEntitie}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_program/create`}
          component={ActualSbdProgramCreate}
        />
        <Route
          exact
          path={`${match.url}/actual_sbd_program/edit/:id`}
          component={ActualSbdProgramUpdate}
        />
        <Route
          exact
          path={`${match.url}/class_batch_map`}
          component={ClassBatchMapEntitie}
        />
        <Route
          exact
          path={`${match.url}/class_batch_map/create`}
          component={ClassBatchMapCreate}
        />
        <Route
          exact
          path={`${match.url}/class_batch_map/edit/:objectId`}
          component={ClassBatchMapUpdate}
        />
        <Route exact path={`${match.url}/year`} component={YearEntitie} />
        <Route
          exact
          path={`${match.url}/year/create`}
          component={AcedamicYearCreate}
        />
        <Route
          exact
          path={`${match.url}/year/edit/:objectId`}
          component={AcedamicYearUpdate}
        /> */}
      </Switch>
    </>
  );
}
