import * as Yup from "yup";

export const table = {
    primaryData: [
      {
        title: "SbdProgramName",
        value: "sbdProgramName",
        type: "configue",
      },
      {
        title: "SbdProgramId",
        value: "sbdProgramId",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      },
      {
        title: "Actions",
        width: "5%",
        sendIsArchived:true
      }, 
    ],
    secondaryData: [],
  };
export const tableRead = {
    primaryData: [
      {
        title: "SbdProgramName",
        value: "sbdProgramName",
        type: "configue",
      },
      {
        title: "SbdProgramId",
        value: "sbdProgramId",
      },
      {
        title: "IsArchived",
        value: "isArchived",
      },
      {
        title: "CreatedAt",
        value: "createdAt",
        type: "configue",
      },
      {
        title: "updatedAt",
        value: "updatedAt",
      }
    ],
    secondaryData: [],
  };

  export type actualSbdProgram = {
    sbdProgramId: string;
    sbdProgramName: string;
};

export const actualSbdProgramInitialValues: actualSbdProgram = {
    sbdProgramId: "",
    sbdProgramName: "",
};

export const actualSbdProgramMandatoryFields = [
  { id: "sbdProgramId" },
  { id: "sbdProgramName" }
];

export const actualSbdProgramValidationSchema = Yup.object().shape({
  sbdProgramId: Yup.string().required("Sbd Program Id is required"),
  sbdProgramName: Yup.string().required("Sbd Program Name is required"),
});

export const actualSbdProgramProperties = [
    {
        id: "sbdProgramId",
        type: "text",
        label: `sbdProgramId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
      {
        id: "sbdProgramName",
        type: "text",
        label: `sbdProgramName`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
];
export const actualSbdProgramPropertiesUpdate = [
    {
        id: "sbdProgramId",
        type: "text",
        label: `sbdProgramId`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
      {
        id: "sbdProgramName",
        type: "text",
        label: `sbdProgramName`,
        className: "name-styles",
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 2.5 },
        required: true,
      },
      {
        id: "isArchived",
        type: "normal-select",
        label: "IsArchived",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        hideNone: true,
      }, 
];
