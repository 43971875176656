import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";
export type ClassBatchFilter = {
  Class: string;
  Batch: string;
  start_date: string;
  end_date: string;
  isArchived:string;
};

export const ClassBatchFilterInitialValues: ClassBatchFilter = {
  Class: "",
  Batch: "",
  start_date: "",
  end_date: "",
  isArchived:"false"
};

export const ClassBatchFilterMandatoryFields = [
  { id: "Class" },
  { id: "Batch" },
  { id: "start_date" },
  { id: "end_date" },
];

export const ClassBatchFilterValidationSchema = Yup.object().shape({});

export const ClassBatchFilterProperties = [
  {
    id: "Class",
    type: "single-select-dependency",
    label: "Class",
    classClass: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["batch_list"],
    dependentFlds: {
      state_list: "state_list",
    },
  },
  {
    id: "Batch",
    type: "normal-select",
    label: "Batch",
    list: "batch_list",
    className: "select-styles",
    required: false,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    onlyApiCall: true,
      requestList: async (payload) => {
        return {};
      },
    endpoint:endpointsMd.GET_BATCH_LIST,
    keyValue: { label: "batch_name", value: "batch_id" },
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];
