import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  batchFilterInitialValues,
  batchFilterProperties,
  batchFilterValidationSchema,
} from "./helper";
import { formatDate } from "../../state/filter/helper";
import { hasLengthGreaterThanZero, hasValidValue } from "pages/entities/helper";
import { useEffect } from "react";

export const BatchFilter = ({ setSearchPayload, setOffset,clearValuesTrigger }) => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    batchFilterInitialValues,
    batchFilterValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();

  const clearFilter = () => {
    setValue("class_list", "");
    setValue("batch_list", "");
    setValue("start_date", "");
    setValue("end_date", "");
    setValue("isArchived","false");
    setValue("isDummy","false");
    setSearchPayload({});
    setOffset(0);
  };
  const handleSearch = () => {
    const payload = {
      name: watch()?.batch_list,
      createdFromDate: formatDate(watch()?.start_date),
      createdToDate: formatDate(watch()?.end_date),
      isArchived:watch()?.isArchived==="true"?true:false,
      isDummy:watch()?.isDummy==="true"?true:false,
    };
    setSearchPayload(payload);
    setOffset(0);
  };
  useEffect(() => {
    clearFilter();
  }, [clearValuesTrigger]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={batchFilterProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <Button
              variant="contained"
              sx={{
                width: "70px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={clearFilter}
              //disabled={hasLengthGreaterThanZero(watch(), "class_list")}
              disabled={!hasValidValue(watch(),"isArchived","isDummy")}
            >
              clear
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleSearch}
              disabled={!hasValidValue(watch(),"isArchived","isDummy")}
            >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
