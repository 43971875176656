import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  branchInitialValues,
  branchMandatoryFields,
  branchProperties,
  branchPropertiesUpdate,
  branchValidationSchema,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_BRANCH,
  UPDATE_BRANCH,
} from "utils/graphql/services/entities/mutations";
import entitesClient from "utils/graphql/entitiesClient";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { GET_BRANCH_LIST } from "utils/graphql/services/entities/queries";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";

export const BranchUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    branchInitialValues,
    branchValidationSchema,
    ""
  );
  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);
  const isDummy = getQueryParam("isDummy", location);

  const [getBranches, { loading, error, data }] = useLazyQuery(
    GET_BRANCH_LIST,
    {
      client: entitesClient,
    }
  );

  const [updateBranch, { loading: loadingUp, error: errorUp, data: dataUp }] =
    useMutation(UPDATE_BRANCH, {
      client: entitesClient,
    });

  const getValueFromMetadata = (
    listValue,
    identifier,
    identifierValue,
    searchValue
  ) => {
    const maindata = metaData[`${listValue}`];
    const filteredData = maindata?.filter((obj) => {
      if (obj[`${identifier}`] == identifierValue) {
        return obj;
      }
    });
    return filteredData[0]?.[`${searchValue}`];
  };

  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const handleUpdateBranch = async () => {
    try {
      const response: any = await updateBranch({
        variables: {
          input: {
            name: watch()?.name,
            objectId: objectId,
            District: getValueFromMetadata(
              "district_list",
              "district_id",
              watch()?.district,
              "district_id"
            ),
            State: getValueFromMetadata(
              "state_list",
              "state_id",
              watch()?.state,
              "state_id"
            ),
            sbdCollegeId: watch()?.sbdCollegeId,
            sbdDistrictId: getValueFromMetadata(
              "district_list",
              "district_id",
              watch()?.district,
              "sbd_district_id"
            ),
            sbdStateId: getValueFromMetadata(
              "state_list",
              "state_id",
              watch()?.state,
              "sbd_state_id"
            ),
            isDummy:watch()?.isDummy=="true"?true:false,
            isArchived:watch()?.isArchived=="true"?true:false
          },
        },
      });

      if (response?.data?.updateBranch?.success) {
        console.log(
          "Branch updated successfully:",
          response?.data?.updateBranch?.message
        );
        dispatch(snackbarRequest({ message: "Updated Branch successfully" }));
        history.replace(`/entities/branch`);
      } else {
        console.log(
          "Branch update failed:",
          response?.data?.updateBranch?.message
        );
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating branch:", err);
      dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));
    }
  };

  //   const handleCreateBranch = async () => {
  //     // console.log(
  //     //   getValueFromMetadata("branch_list", "district_name", watch()?.district,"sbd_district_id")
  //     // );
  //     try {
  //       const response:any = await createBranch({
  //         variables: {
  //           input: {
  //             name: watch()?.name,
  //             District: watch()?.district,
  //             State: getValueFromMetadata("state_list", "state_id", watch()?.state,"state_name"),
  //             sbdCollegeId:watch()?.sbdCollegeId,
  //             sbdDistrictId: getValueFromMetadata("branch_list", "district_name", watch()?.district,"sbd_district_id"),
  //             sbdStateId: getValueFromMetadata("state_list", "state_id", watch()?.state,"sbd_state_id"),
  //           },
  //         },
  //       });
  //       if(!isEmpty(response?.data?.createBranch)){
  //         dispatch(snackbarRequest({ message: "Created Branch successfully" }));
  //         history.replace(`/entities/branch`);
  //       }else{
  //         dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));
  //       }
  //       //console.log("Branch created:", response.data.createBranch);
  //     } catch (err) {
  //       console.error("Error creating branch:", err);
  //       dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));
  //     }
  //   };

  useEffect(() => {
    if (data?.getBranch) {
      const { getBranch } = data;
      if (!isEmpty(getBranch[0])) {
        setValue("name", getBranch[0]?.name);
        setValue("state", getBranch[0]?.State);
        setValue("district", getBranch[0]?.District);
        setValue("sbdCollegeId", getBranch[0]?.sbdCollegeId);
        setValue("sbdStateId", getBranch[0]?.sbdStateId);
        setValue("sbdDistrictId", getBranch[0]?.sbdDistrictId);
        setValue("isDummy", getBranch[0]?.isDummy == true ? "true" : "false");
        setValue("isArchived",getBranch[0]?.isArchived==true?"true":"false");
      }
    }
  }, [data]);
  useEffect(() => {
    getBranches({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
          isDummy: isDummy === "true" ? true : false,
        },
      },
    });
  }, []);

  useEffect(()=>{
    if(watch()?.state==""){
      updateMetaData([],"district_list");
    }
  },[watch()?.state]);

  return (
    <div>
      <TypographyHeading>Update branch</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={branchPropertiesUpdate}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={loadingUp}
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateBranch}
              disabled={!isFieldsValidate(errors, branchMandatoryFields, getValues)}
            >
              Update Branch
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
