import useReactHookForm from "hooks/useReactHookForm";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Button, Grid, Paper } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import {
  acedamicYearInitialValues,
  acedamicYearMandatoryFields,
  acedamicYearProperties,
  acedamicYearValidationSchema,
  updateAcedamicYearProperties,
} from "./helper";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import {
  CREATE_YEAR,
  UPDATE_YEAR,
} from "utils/graphql/services/entities/mutations";
import { getQueryParam, isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { GET_YEAR_LIST } from "utils/graphql/services/entities/queries";
import { useEffect } from "react";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getAcademicYearListData } from "utils/metadata-split/metaDataApiFetch";

export const AcedamicYearUpdate = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    acedamicYearInitialValues,
    acedamicYearValidationSchema,
    ""
  );
  const [getYears, { loading, error, data }] = useLazyQuery(GET_YEAR_LIST, {
    client: entitesClient,
  });
  const [updateYear, { loading: loadingUp, error: errorUp, data: Up }] =
    useMutation(UPDATE_YEAR, {
      client: entitesClient, // Use your GraphQL client here
    });
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const location = useLocation();
  const objectId = getQueryParam("objectId", location);
  const isArchived = getQueryParam("isArchived", location);

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  
  const updateMetaData = (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    dispatch(metadataSuccess(metadataList));
  };
  
  const handleUpdateYearListDate = async () => {
   try {
    const call1:any= await  getAcademicYearListData(updateMetaData)
    updateMetaData(call1[0], call1[1]);
   } catch (error) {
     console.log(error);
   }
  };

  const handleUpdateYear = async () => {
    let obj = {};
    if (watch()?.downloadstatsViewStatus == "true") {
      obj["downloadstatsViewStatus"] = true;
    }
    try {
      const response:any = await updateYear({
        variables: {
          input: {
            objectId: objectId,
            // isDummy: watch()?.isDummy == "false" ? false : true,
            name: watch()?.name,
            academicYear: watch()?.academicYear,
          //  yearId: +watch()?.yearId,
            isUpcoming: watch()?.isUpcoming == "false" ? false : true,
            isArchived: watch()?.isArchived == "true" ? true : false,
            sbdYearId: watch()?.sbdYearId,
            metadata: {
              ...obj,
            },
          },
        },
      });

      if (response?.data?.updateYear?.success) {
        console.log(
          "Year updated successfully:",
          response?.data?.updateYear?.message
        );
        dispatch(
          snackbarRequest({ message: "Updated Acedamic Year successfully" })
        );
        await handleUpdateYearListDate();
        history.replace(`/entities/year`);
      } else {
        console.log("Year update failed:", response?.data?.updateYear?.message);
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error updating year:", err);
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  const handleCreateYear = async () => {
    let obj = {};
    if (watch()?.downloadstatsViewStatus == "true") {
      obj["downloadstatsViewStatus"] = true;
    }
    try {
      const response: any = await updateYear({
        variables: {
          input: {
            name: watch()?.name,
            academicYear: watch()?.academicYear,
           // yearId: +watch()?.yearId,
            isUpcoming: watch()?.isUpcoming == "false" ? false : true,
            sbdYearId: watch()?.sbdYearId,
            metadata: {
              ...obj,
            },
          },
        },
      });
      //if(res?.data?.)
      if (!isEmpty(response?.data?.createYear)) {
        console.log("SbdBatch created:", response?.data?.createYear);
        dispatch(
          snackbarRequest({ message: "Created Acedamic Year successfully" })
        );
        history.replace(`/entities/year`);
      } else {
        dispatch(
          snackbarRequest({
            message: `${response?.errorReason}`,
            type: "error",
          })
        );
      }
    } catch (err) {
      dispatch(snackbarRequest({ message: `${err}`, type: "error" }));
    }
  };

  useEffect(() => {
    if(data?.getYear){
        const{getYear}=data
        if(!isEmpty(getYear[0])){
            setValue("name",getYear[0]?.name);
            setValue("academicYear",getYear[0]?.academicYear);
           // setValue("yearId",getYear[0]?.yearId);
            setValue("isUpcoming",getYear[0]?.isUpcoming==true?"true":"false");
            setValue("sbdYearId",getYear[0]?.sbdYearId);
            setValue("downloadstatsViewStatus",getYear[0]?.metadata?.downloadstatsViewStatus==true?"true":"false");
            setValue("isArchived",getYear[0]?.isArchived==true?"true":"false");
            // setValue("isDummy",getYear[0]?.isDummy==true?"true":"false");
        }
    }

  }, [data]);

  useEffect(() => {
    getYears({
      variables: {
        filter: {
          objectId: objectId,
          isArchived: isArchived === "true" ? true : false,
        },
      },
    });
  }, []);

  if(loading){
    return <p>loading......</p>
  }

  return (
    <div>
      <TypographyHeading>Update Acedamic Year</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={updateAcedamicYearProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
               loadingPosition="start"
              sx={{
                width: "200px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 23.5px 7px 23.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleUpdateYear}
              disabled={!isFieldsValidate(errors, acedamicYearMandatoryFields, getValues)}
              loading={loadingUp}
            >
              update Acedamic Year
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
