import * as Yup from "yup";
export type stateFilter = {
    name: string;
    start_date: string;
    end_date: string;
    isArchived:string;
    isDummy:string;
};

export const stateFilterInitialValues: stateFilter = {
  name: "",
  start_date: "",
  end_date: "",
  isArchived: "false",
  isDummy: "false",
};

export const stateFilterMandatoryFields = [
  { id: "name" },
  { id: "start_date" },
  { id: "end_date" },
];

export const stateFilterValidationSchema = Yup.object().shape({

});

export const stateFilterProperties = [
  {
    id: "state_list",
    type: "single-select-dependency",
    label: "State",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_name" },
    dependentFields: [""],
    dependentFlds: {
      state_list: "state_list",
    },
  },
  {
    id: "start_date",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "Created To",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: false,
  },
  {
    id: "isDummy",
    type: "normal-select",
    label: "isDummy",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
  {
    id: "isArchived",
    type: "normal-select",
    label: "IsArchived",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone: true,
  }, 
];

export function formatDate(isoString) {
    if(isoString==""){
        return "";
    }
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }