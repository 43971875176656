import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import { GET_BRANCH_LIST } from "utils/graphql/services/entities/queries";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import NoDataFound from "shared/molecules/no-data";
import { table, tableRead } from "./helper";
import { BranchFilter } from "./filter";
import { DELETE_BRANCH } from "utils/graphql/services/entities/mutations";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { isEmpty } from "utils/helpers";
import { getValuesByIds } from "pages/entities/helper";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { getDistrictListData, getStateListData } from "utils/metadata-split/metaDataApiFetch";
import { academicTagsUser, checkFeatureAccess } from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function BranchEntitie() {
  const classes = useStyles();
  const [limit,setLimit]=useState(10);
  const [offset,setOffset]=useState(0);
  const [searchPayload,setSearchPayload]=useState({});
  const [tableData, setTableData] = useState([]);
  const [showLoading, setShowIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isLoading, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const [getBranches, { loading, error, data }] = useLazyQuery(
    GET_BRANCH_LIST,
    {
      client: entitesClient,
    }
  );
  const [deleteBranch, { loading:loadingD, error:errorD, data:dataD }] = useMutation(DELETE_BRANCH, {
    client: entitesClient, // Ensure you're using the correct GraphQL client instance
  });

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const updateMetaData = (listData, metaKey, listData2, metaKey2) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    dispatch(metadataSuccess(metadataList));
    setShowIsLoading(false);
  };

  const handleEdit = (objectId,isArchieve,isDummy) => {
    // const { objectId } = obj;
    history.push(`/entities/branch/edit?objectId=${objectId}&isArchived=${isArchieve}&isDummy=${isDummy}`);
  };
  
  const handleDeleteBranch = async (objectId) => {
    try {
      const response:any = await deleteBranch({
        variables: { objectId },
      });
  
      if (response?.data?.deleteBranch?.success) {
        console.log("Branch deleted successfully:", response?.data?.deleteBranch?.message);
        dispatch(snackbarRequest({ message: "Branch deleted successfully" }));
        getBranches({ variables: { filter: {limit:10,offset:0} } });
        setLimit(10);
        setOffset(0);
      } else {
        console.log("Branch deletion failed:", response?.data?.deleteBranch?.message);
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));

      }
    } catch (err) {
      console.error("Error deleting branch:", err);
      dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));
    }
  };

  useEffect(() => {
    if (data?.getBranch) {
      const { getBranch } = data;
      const { exam_model_list } = metaData;
      if (!isEmpty(getBranch)) {
        // convertToTableData();//exam_model_list
        const tabledata = getBranch.map((obj) => {
          console.log("obj", obj);
          const tableRow = { ...obj };
          tableRow["State"] = getValuesByIds(obj?.State,
            "state_id","state_name","state_list",metaData
          );
          tableRow["District"] = getValuesByIds(obj?.District,
            "district_id","district_name","district_list",metaData
          );
          return tableRow;
        });
        setTableData(tabledata);
      } else {
        setTableData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (showLoading === false) {
      getBranches({ variables: { filter: {...searchPayload,limit,offset} } });
    }
   // getBranches({ variables: { filter: {...searchPayload} } });
  }, [searchPayload,offset,showLoading]);

  useEffect(() => {
    (async () => {
      try {
        const call1:any= await getStateListData(updateMetaData);
        const call2: any = await getDistrictListData(updateMetaData);
        updateMetaData(call1[0], call1[1], call2[0], call2[1]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  if (showLoading) {
    return <p>fetching State and District data Please wait....</p>;
    // return <LoaderModal message="fetching class Batch data Please wait...."  />;
  }
  
  return (
    <div>
      {hasModificationAccess && (
        <ContentRight>
          <Link to="branch/create" className={classes.label}>
            <Button variant="contained">Create Branch </Button>
          </Link>
        </ContentRight>
      )}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        Branch
      </TypographyHeading>
      <BranchFilter setSearchPayload={setSearchPayload} setOffset={setOffset} />
      <Paper elevation={2} sx={{ px: 2, pb: 4, paddingTop: 4, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && tableData.length > 0 ? (
            <SectionTable
              data={tableData}
              table={hasModificationAccess===true?table:tableRead}
              id="objectId"
              name="objectId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteBranch}
              rowsPerPageCount={10}
              style="false"
              totlaRecords={0}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            // <CollapsibleTable
            //   data={tableData}
            //   table={table}
            //   id="objectId"
            //   name="objectId"
            //   handleEdit={handleEdit}
            //   handleDelete={handleDeleteBranch}
            //   rowsPerPageCount={tableData?.length > 0 ? tableData?.length : 10}
            //   style="false"
            //   totlaRecords={0}
            //   paginationSegmentApi="false"
            //   hasPageApiCall={false}
            //   showPaginationFooter={false}
            // />
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data?.getBranch?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
