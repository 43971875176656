import { Box, Button, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useLazyQuery, useMutation } from "@apollo/client";
import entitesClient from "utils/graphql/entitiesClient";
import { useEffect, useState } from "react";
import Loader from "pages/build-test/loader";
import SectionTable from "shared/organisms/tables/table";
import NoDataFound from "shared/molecules/no-data";
import { GET_ACTUALSBDBATCH_LIST } from "utils/graphql/services/entities/queries";
import { table, tableRead } from "./helper";
import { ActualSbdBatchFilter } from "./filter";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { DELETE_ACTUAL_SBD_BATCH } from "utils/graphql/services/entities/mutations";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { academicTagsUser, checkFeatureAccess } from "utils/role-restriction/helper";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export function ActualSbdBatchEntitie() {
  const classes = useStyles();
  const [limit,setLimit]=useState(10);
  const [offset,setOffset]=useState(0);
  const [searchPayload,setSearchPayload]=useState({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [getActualSbdBatch, { loading, error, data }] = useLazyQuery(
    GET_ACTUALSBDBATCH_LIST,
    {
      client: entitesClient,
    }
  );
  const [deleteActualSbdBatch, { loading:loadingD, error:errorD, data:dataD}] = useMutation(DELETE_ACTUAL_SBD_BATCH, {
    client: entitesClient, // Ensure you're using the correct GraphQL client instance
  });

  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const handleEdit = (objectId,isArchieve) => {
    history.push(`/entities/actual_sbd_batch/edit?objectId=${objectId}&isArchived=${isArchieve}`);
  };
  
  const handleDeleteBatch = async (id) => {
    try {
      const response:any = await deleteActualSbdBatch({
        variables: { id: parseInt(id) }, // Ensure id is an integer
      });
  
      if (response?.data?.deleteActualSbdBatch?.success) {
        console.log("Batch deleted successfully:", response?.data?.deleteActualSbdBatch?.message);
        console.log("Year deleted successfully:", response?.data?.deleteYear?.message);
        dispatch(snackbarRequest({ message: "Sbd Batch deleted successfully" }));
        getActualSbdBatch({ variables: { filter:  {limit:10,offset:0} } });

        setLimit(10);
        setOffset(0);
      } else {
        console.log("Batch deletion failed:", response?.data?.deleteActualSbdBatch?.message);
        dispatch(snackbarRequest({ message: `${response?.errorReason}`,type: "error"  }));

      }
    } catch (err) {
      console.error("Error deleting batch:", err);
      dispatch(snackbarRequest({ message: `${err}`,type: "error"  }));

    }
  };

  useEffect(() => {
   getActualSbdBatch({ variables: { filter:  {...searchPayload,limit,offset} } });
   // getActualSbdBatch({ variables: { filter:  {...searchPayload} } });
  }, [searchPayload,limit,offset]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,academicTagsUser.ACADEMIC_TAGS_READ_WRITE
  );

  return (
    <div>
      {hasModificationAccess && (
        <ContentRight>
          <Link to="actual_sbd_batch/create" className={classes.label}>
            <Button variant="contained">Create Sbd Batch</Button>
          </Link>
        </ContentRight>
      )}
      <TypographyHeading sx={{ mb: 2, mt: 3, fontSize: "25px" }}>
        Sbd Batch
      </TypographyHeading>
      <ActualSbdBatchFilter
        setSearchPayload={setSearchPayload}
        setOffset={setOffset}
      />
      <Paper elevation={2} sx={{ px: 2, pb: 4, paddingTop: 4, mt: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && data?.getActualSbdBatch.length > 0 ? (
            <SectionTable
              data={data?.getActualSbdBatch}
              table={hasModificationAccess===true?table:tableRead}
              id="id"
              name="sbdBatchId"
              handleEdit={handleEdit}
              handleDelete={handleDeleteBatch}
              rowsPerPageCount={10}
              style="false"
              totlaRecords={0}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data?.getActualSbdBatch?.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
